import React, { useRef, useEffect, useState } from "react";
import { RegularPolygon, Rect, Text, Transformer } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { removeInFrames } from "../../../Redux/actions/frame";
import { editStageTool } from "../../../Redux/actions/tools";
import TextInput  from "./HexagonText";
import ShapeStyle from "./ShapeStyle";

const Hexagon = React.memo(({ shapeProps }) => {
  
  const shapeRef = useRef();
  const textNodeRef = useRef();
  const dispatch = useDispatch();
  const { selectedFrame } = useSelector((store) => store.frameRoot);
  const { editToolName, editToolData } = useSelector((store) => store.toolRoot);
  const [editing, setEditing] = useState(shapeProps?.id === editToolData?.id);
  const [transform, setTransform] = useState(false);

  useEffect(() => {
    if (editing) {
      textNodeRef.current.nodes([shapeRef.current]);
      textNodeRef.current.getLayer().batchDraw();
    }
  }, [editing]);

  useEffect(() => {
    // Remove transformer when selectedSquare.id changes
    if (shapeProps.id !== editToolData?.id && editing) {
      setEditing(false);
    }
  }, [shapeProps.id, editToolData?.id, editing]);

  const handleDragEnd = () => {
    const node = shapeRef.current;
    const x = node.x();
    const y = node.y();
    const recWidth = editToolData?.sideLength / Math.sqrt(2);
    const recHeight = editToolData?.sideLength / Math.sqrt(3);
    const recX = x - recWidth / 2;
    const recY = y - recHeight / 2;
    dispatch(
      editStageTool(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...editToolData,
          hexagonCenterX: node.x(),
          hexagonCenterY: node.y(),
          rectangleX: recX,
          rectangleY: recY,
        },
        editToolName
      )
    );
  };

  const handleDoubleClick = () => {
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: {
        isEditToolSelected: true,
        editToolName: "hexagon",
        editToolData: shapeProps,
        editedToolId: shapeProps.id,
      },
    });
    setEditing(true);
    setTransform(true);
  };

  const handleBlur = (deleting) => {
    if (
      editToolData &&
      Object.keys(editToolData).length > 0 &&
      !deleting &&
      !transform
    ) {
      setEditing(false);
      dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData, editToolName));
    }
  };

  const onDragStart = () => {
    setTransform(true);
    handleDoubleClick();
  };
  const handleChange = (value) => {
    setTransform(false);
    dispatch({
      type: "UPDATE_EDIT_SELECTED_TOOL",
      payload: { name: "text", value },
    });
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const newSideLength = shapeProps.sideLength * scaleX;
    const newWidth = 2 * newSideLength * Math.sqrt(3)/2;
    const x = node.x() * scaleX;
    const y = node.y() * scaleY;
    const recWidth = newSideLength / Math.sqrt(2);
    const recHeight = newSideLength / Math.sqrt(3);
    const recX = x - recWidth / 2;
    const recY = y - recHeight / 2;
    let data = {
      hexagonCenterX: x,
      hexagonCenterY: y,
      rectangleWidth: recWidth,
      rectangleHeight: recHeight,
      rectangleX: recX,
      rectangleY: recY,
      width: newWidth,
      sideLength: newSideLength,
      radius: newSideLength / Math.sqrt(3),
    };
    dispatch(
      editStageTool(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...editToolData,
          ...data,
        },
        editToolName
      )
    );
  };
  return (
    <React.Fragment>
      <RegularPolygon
        onClick={() => handleDoubleClick()}
        ref={shapeRef}
        {...shapeProps}
        x={shapeProps?.hexagonCenterX}
        y={shapeProps?.hexagonCenterY}
        draggable={true}
        stroke={0.5}
        sides={6}
        radius={shapeProps.radius}
        onTransformStart={handleDoubleClick}
        onTransformEnd={handleTransformEnd}
        onDragStart={onDragStart}
        onDragEnd={handleDragEnd}
      />

      <Rect
        x={shapeProps?.rectangleX}
        y={shapeProps?.rectangleY}
        width={shapeProps?.rectangleWidth}
        height={shapeProps?.rectangleHeight}
        id="rectangle"
        draggable
        onClick={handleDoubleClick}
      />
      <Text
        fontSize={15}
        fill="white"
        x={shapeProps?.rectangleX}
        y={shapeProps?.rectangleY}
        width={shapeProps?.rectangleWidth}
        height={shapeProps?.rectangleHeight}
        align="center"
        verticalAlign="middle"
        id="rectangleId"
        onClick={handleDoubleClick}
        ref={textNodeRef}
      />
      <TextInput
        editing={editing}
        x={shapeProps?.rectangleX}
        y={shapeProps?.rectangleY}
        value={shapeProps?.text}
        width={shapeProps?.rectangleWidth}
        height={shapeProps?.rectangleHeight}
        onBlur={() => handleBlur(false)}
        onChange={(e) => handleChange(e)}
        onSelect={() => handleDoubleClick()}
      />

      {editing && (
        <ShapeStyle
          x={shapeProps.hexagonCenterX}
          y={shapeProps.hexagonCenterY- (shapeProps.width / 2)}
          onClose={() => setEditing(false)}
          onDelete={() => {
            dispatch(
              removeInFrames(
                selectedFrame?.boardId,
                selectedFrame?._id, 
                editToolData,
                "hexagon")
            );
          }}
          onColorChange={(color) =>
            dispatch(
              editStageTool(
                selectedFrame?.boardId,
                selectedFrame?._id,
                {
                  ...editToolData,
                  fill: color,
                },
                editToolName
              )
            )
          }
        />
      )}
      {editing && (
        <Transformer
          ref={textNodeRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.radius < 50) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
})
export default Hexagon;
