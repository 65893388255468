import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { shareBoard } from "../../Redux/actions/boards";
import { getUsers } from "../../Redux/actions/user";
import styles from "./inviteBoardInput.module.css";
import classNames from "classnames";

const InviteBoardInput = () => {
  let dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [findUser, setfindUser] = useState([]);
  const [selectedUser, setselectedUser] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [memberid, setMemberId] = useState("");
  const [isSharing, setIsSharing] = useState(false);
  const [btnClick, setBtnClick] = useState(true);
  const [show, setShow] = useState(true);
  const [email, setEmail] = useState("");
  const [shared, setShared] = useState("");
  const { selectedBoard } = useSelector((store) => store.boardRoot);
  const { loggedInUser } = useSelector((state) => state.frameRoot);

  console.log("selectedBoard",selectedBoard)
  let boardId = selectedBoard?._id;
  const getData = useCallback(async () => {
    dispatch(
      getUsers(async function (response) {
        let users = [];
        response.forEach((element) => {
          users.push(element);
        });
        setUsers(users);
        setfindUser(users);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);
  const handleSearchChange = useCallback(
    (e) => {
      setselectedUser(e.target.value);
      let searchQuery = String(e.target.value).toLowerCase();
      if (searchQuery !== "") {
        var data = users.filter((data) => {
          return (
            (data.username &&
              String(data.username).toLowerCase().includes(searchQuery)) ||
            (data.email && data.email.toLowerCase().includes(searchQuery))
          );
        });
        if (data.length > 0) {
          setfindUser(data);
          setShow(true);
        } else {
          if (!/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(searchQuery)) {
            setValidEmail(false);
            setEmail(searchQuery);
            setfindUser([]);
            setMemberId("");
            setBtnClick(true);
          } else {
            setEmail(searchQuery);
            setValidEmail(true);
            setfindUser([]);
            setMemberId("");
            setBtnClick(false);
          }
        }
      } else {
        setfindUser(users);
      }
    },
    [users]
  );

  const handleShare = (payload) => {
    if (isSharing) {
      return;
    }
    setIsSharing(true);
    dispatch(
      shareBoard(payload, boardId, async function (response) {
        if (response) {
          setselectedUser("");
          setEmail("");
          setBtnClick(true);
          setShared(true);
        } else {
          setShared(false);
        }
        setTimeout(() => {
          setIsSharing(false);
          setShared("");
        }, 3000);
      })
    );
  };
  const handleInvite = useCallback(async () => {
    if (!memberid) {
      var member = users.find((data) => {
        return (
          (data.username &&
            String(data.username).toLowerCase().includes(selectedUser)) ||
          (data.email &&
            String(data.email).toLowerCase().includes(selectedUser))
        );
      });

      if (member) {
        let payload = {
          memberid: member._id,
          email: member.email,
        };
        handleShare(payload);
      }
    }

    if (memberid) {
      let payload = {
        memberid,
        email,
      };
      handleShare(payload);
    }
  }, [memberid, users, selectedUser, boardId, email]);

  const handleSelect = useCallback((user) => {
    if (user.username) {
      setselectedUser(user.username);
    } else {
      setselectedUser(user.email);
    }
    setEmail(user.email);
    setMemberId(user._id);
    setBtnClick(false);
  }, []);
  return (
    <div className="modal-body">
      <div className="dropdown">
        <div className={styles.outer_div}>
          <div className={styles.input_div}>
            <input
              className={styles.input_box}
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              placeholder="Email address or name"
              value={selectedUser}
              onChange={(e) => handleSearchChange(e)}
              autoComplete="off"
            />

            <ul
              className="dropdown-menu  scrollable-menu px-3 m-2"
              aria-labelledby="dropdownMenuLink"
              style={{
                minHeight: "70px",
                maxHeight: "250px",
                width: "325px",
                overflowY: "scroll",
              }}
            >
              {findUser?.length > 0 ? (
                <>
                  {show &&
                    findUser &&
                    findUser.map((user) => (
                      <>
                        <div className="d-flex mb-2 p-2 bg_hover_color">
                          <span
                            className={styles.user_icon}
                            style={{
                              backgroundColor: user.userColor
                                ? user.userColor
                                : "#1960ee",
                              color: "white",
                            }}
                          >
                            {user && user.username && user.username ? (
                              user.username.charAt(0).toUpperCase()
                            ) : (
                              <i className="fa-regular fa-user"></i>
                            )}
                          </span>
                          <div
                            onClick={() => handleSelect(user)}
                            className={styles.user_name}
                          >
                            {user && user.username && user.username}
                          </div>
                        </div>
                      </>
                    ))}
                </>
              ) : validEmail ? (
                <Card style={{ width: "285px" }}>
                  <p className="text-muted text-center">
                    Invite {selectedUser} Email Address.
                  </p>
                </Card>
              ) : (
                <Card style={{ width: "285px" }}>
                  <p className="text-muted text-center">
                    Add their Email Address to Invite them.
                  </p>
                </Card>
              )}
            </ul>
          </div>
          <div className={styles.button_div}>
            <button
              className={styles.share_btn}
              onClick={handleInvite}
              disabled={btnClick}
            >
              Share
            </button>
          </div>
        </div>
      </div>
      {shared ? (
        <p style={{ color: "green" }}>board shared successfully.</p>
      ) : shared === false ? (
        <p style={{ color: "red" }}>board already shared.</p>
      ) : (
        ""
      )}

      <>
        {selectedBoard?.boardMember ? (
          selectedBoard.boardMember
            .slice()
            .sort((a, b) => {
              if (a?.b_memberid?._id === loggedInUser?.userId?._id) return -1;
              if (b?.b_memberid?._id === loggedInUser?.userId?._id) return 1;
              return 0;
            })
            .map(
              (members, key) =>
                members?.b_memberid && (
                  <Row key={key}>
                    <div className="d-flex justify-content-between">
                      <span
                        className={classNames(
                          "d-flex ",
                          "justify-content-center ",
                          "rounded-circle",
                          styles.memberIcon
                        )}
                        style={{
                          width: "44px",
                          height: "44px",
                          alignItems: "center",
                          backgroundColor: members.b_memberid
                            ? members.b_memberid.userColor
                            : "namecircle_color",
                        }}
                      >
                        {members?.b_memberid?.email && (
                          <span className={styles.nameCircleInnerName}>
                            {members.b_memberid.username.split(" ").length === 1
                              ? members.b_memberid.username
                                  .toUpperCase()
                                  .charAt(0)
                              : members.b_memberid.username
                                  .split(" ")
                                  ?.slice(0, 2)
                                  .map((word) => word?.charAt(0)?.toUpperCase())
                                  .join("")}
                          </span>
                        )}
                      </span>

                      <Col className="mx-2">
                        <Row>
                          <span
                            className={classNames("", styles.share_name_modal)}
                          >
                            {members &&
                              members.b_memberid &&
                              members.b_memberid.username && (
                                <p>{members.b_memberid.username}</p>
                              )}
                          </span>
                        </Row>

                        <Row style={{ marginTop: "-14px" }}>
                          <span className={styles.span_div}>
                            {members &&
                              members.b_memberid &&
                              members.b_memberid.email && (
                                <p
                                  className={classNames(
                                    "",
                                    styles.share_email_modal
                                  )}
                                >
                                  {members.b_memberid.email}
                                </p>
                              )}
                          </span>
                        </Row>
                      </Col>

                      {/* <div className="dropdown member_dropdown text-end">
                          <div
                            className="nav-link dropdown-toggle d-flex flex-row justify-content-between"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <h6>
                              {members?.b_memberid?._id === user_id
                                ? "Admin"
                                : "Member"}
                            </h6>
                          </div>
                          <div
                            className="dropdown-menu dropdown-menu-end px-3"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <span className="dropdown-item">
                              <label
                                className="board_label"
                              >
                                <svg
                                  viewBox="0 0 512 512"
                                  fill="currentColor"
                                  height="1.5em"
                                  width="1.5em"
                                  style={{
                                    marginRight: ".5rem",
                                  }}
                                >
                                  <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                                </svg>
                                {members?.b_memberid?._id === user_id
                                  ? "Close Board"
                                  : "Remove from Board"}
                              </label>
                            </span>
                          </div>
                        </div> */}
                    </div>
                  </Row>
                )
            )
        ) : (
          <div>No board members found</div>
        )}
      </>
    </div>
  );
};

export default InviteBoardInput;
