import React, { useState, useEffect, useRef, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { userLogout } from '../../redux/action/userAction';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import UnfoldMoreSharpIcon from "@mui/icons-material/UnfoldMoreSharp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ManageHistorySharpIcon from "@mui/icons-material/ManageHistorySharp";
import PhotoSizeSelectSmallSharpIcon from "@mui/icons-material/PhotoSizeSelectSmallSharp";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import whiteboardLogo from "../../assets/Images/whiteboardLogo.png";
import Grid from "../../assets/Images/grid.png";
import Risk from "../../assets/Images/Risk.png";
import Task from "../../assets/Images/Task.ico";
import Cxf from "../../assets/Images/cx-f.ico";
import Form from "../../assets/Images/form-f.svg";
import Idea from "../../assets/Images/Idea-deployer-favicon.ico";
import Drive from "../../assets/Images/d-favicon.png";

//for grid icon
import { useCookies } from "react-cookie";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";
import classNames from "classnames";
import { userLoginHelper, userLogout } from "../../Redux/actions/userDetail";

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((store) => store.frameRoot.user);
  //Function to decode token
  useEffect(() => {
    dispatch(userLoginHelper());
  }, []);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = useState(false);
  const [showAddNewSidebar, setShowAddNewSidebar] = useState(false);
  const addNewSidebarRef = useRef(null);
  const [activeTab, setActiveTab] = useState("");
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        addNewSidebarRef.current &&
        !addNewSidebarRef.current.contains(event.target)
      ) {
        setShowAddNewSidebar(false);
        setActiveTab("");
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const handleTabClick = useCallback((tab) => {
    setActiveTab(tab.id);
    setShowAddNewSidebar(tab.id);
  }, []);
  const clearCookieFromAllDomains = useCallback(() => {
    const domain = window.location.hostname;
    const path = "/"; // Adjust the path based on your cookie configuration

    // Clear the cookie for the current domain
    Cookies.remove("account_token", { domain, path });

    // Clear the cookie for each subdomain
    const subdomains = getSubdomains(domain);
    subdomains.forEach((subdomain) => {
      Cookies.remove("account_token", { domain: subdomain, path });
    });
    window.location.reload();
  }, []);

  // Helper function to get subdomains of a domain
  const getSubdomains = useCallback((domain) => {
    const domainParts = domain.split(".");
    const subdomains = [];

    for (let i = domainParts.length - 2; i >= 0; i--) {
      subdomains.push(domainParts.slice(i).join("."));
    }

    return subdomains;
  }, []);

  const toggleDrawer = useCallback(
    (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    },
    []
  );
  // for grid icon
  const [selectedDomain, setSelectedDomain] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["selectedDomain"]);

  useEffect(() => {
    // Retrieve the stored domain from the cookie
    const storedDomain = cookies.selectedDomain;
    if (storedDomain) {
      setSelectedDomain(storedDomain);
    }
  }, [cookies]);

  const handleDomainChange = useCallback(
    (newDomain) => {
      // Set the selected domain in the cookie
      setCookie("selectedDomain", newDomain, { path: "/" });

      // Update the selected domain state
      setSelectedDomain(newDomain);

      try {
        // Open a new tab with a blank page
        const newTab = window.open("about:blank", "_blank");

        // Set the location of the new tab to the selected domain
        newTab.location.href = `https://${newDomain}`;
      } catch (error) {
        console.error("Error navigating to the selected domain:", error);
      }
    },
    [setCookie]
  );
  // for logout
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    // dispatch(userLogout(navigate, removeCookie));
    dispatch(userLogout());
    clearCookieFromAllDomains();
    navigate("/");
  }, []);
  const tabs = [
    { id: "addNew", label: "Add New", icon: <AddOutlinedIcon /> },
    { id: "sort", label: "Sort by", icon: <UnfoldMoreSharpIcon /> },
    { id: "date", label: "Date & Time", icon: <ManageHistorySharpIcon /> },
    { id: "size", label: "Size", icon: <PhotoSizeSelectSmallSharpIcon /> },
  ];
  const list = (
    <Box
      className={styles.drawer_nav}
      sx={{ width: 280 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={styles.mobile_view_logo}>
        <img src={Grid} alt="grid" className={styles.logo_image} />
      </div>
      <div className={styles.mobile_sidebar}>
        <div className={styles.mobile_side_contain}>
          <div className={styles.mobile_sidebar_tab2}>
            {tabs.map((tab, index) => (
              <div
                key={tab.id}
                className={`styles.mobile_sidebar_tab ${
                  activeTab === tab.id ? styles.active : ""
                }`}
                onClick={handleTabClick.bind(this, tab)}
              >
                <div className={styles.side_icons}>
                  {tab.icon}
                  <span>{tab.label}</span>
                </div>
                <KeyboardArrowRightIcon />
              </div>
            ))}
          </div>
          <Divider />
          <List>
            <div className={styles.mobile_sidebar_tab2}>
              <Link to="/users" className={styles.link_color}>
                <div className={styles.mobile_sidebar_tab_activity}>
                  <div className={styles.side_icons}>
                    <PeopleOutlineIcon />
                    <span>User</span>
                  </div>
                </div>
              </Link>

              <Link to="/roles" className={styles.link_color}>
                <div className={styles.mobile_sidebar_tab_activity}>
                  <div className={styles.side_icons}>
                    <ManageAccountsOutlinedIcon />
                    <span>Role</span>
                  </div>
                </div>
              </Link>
              <Link to="/groups" className={styles.link_color}>
                <div className={styles.mobile_sidebar_tab_activity}>
                  <div className={styles.side_icons}>
                    <GroupsOutlinedIcon />
                    <span>Group</span>
                  </div>
                </div>
              </Link>
            </div>
          </List>
        </div>
      </div>
    </Box>
  );
  // for backend color
  const userColor = user?.userId?.userColor;
  return (
    <>
      <div>
        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
          {list}
        </Drawer>
      </div>
      <div className={styles.drive_navbar}>
        <div className={styles.drive_section}>
          <div className={styles.section_of_img}>
            <div className={styles.hum_berger}>
              <MenuIcon onClick={toggleDrawer(true)} />
            </div>
            <Link to="/" className={styles.custom_link}>
              <img
                src={whiteboardLogo}
                style={{
                  cursor: "pointer",
                  maxHeight: "120px",
                  maxWidth: "140px",
                }}
                alt="missing"
              />
            </Link>
          </div>
          <div className={styles.user_details}>
            {/* <HelpOutlineOutlinedIcon />
                        <SettingsOutlinedIcon /> */}
            <div className="dropdown">
              <button
                className={classNames(
                  styles.hidden_arrow,
                  "d-flex",
                  "dropdown-toggle"
                )}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img className={styles.image_icons} src={Grid} alt="icon" />
              </button>
              <ul className={classNames(styles.switch_to, "dropdown-menu")}>
                <h6 className={styles.switch__text01}>Switch to</h6>

                <div className=" ">
                  <Link
                    className={styles.link_css}
                    onClick={handleDomainChange.bind(
                      this,
                      "task.appsdeployer.com"
                    )}
                  >
                    <div
                      className={classNames(
                        styles.dropdown_item,
                        "dropdown-item"
                      )}
                    >
                      <span className={classNames("text-muted", "text-sm")}>
                        <img
                          className={styles.switch_dots}
                          src={Task}
                          alt="icon"
                        />
                      </span>
                      Taskdeployer
                    </div>
                  </Link>
                </div>
                <div className={classNames("dropdown-divider")}></div>
                <div className=" ">
                  <Link
                    className={styles.link_css}
                    onClick={handleDomainChange.bind(
                      this,
                      "cx.appsdeployer.com"
                    )}
                  >
                    <div
                      className={classNames(
                        styles.dropdown_item,
                        "dropdown-item"
                      )}
                    >
                      <span className={classNames("text-muted", "text-sm")}>
                        <img
                          className={styles.switch_dots}
                          src={Cxf}
                          alt="icon"
                        />
                      </span>
                      Cxdeployer
                    </div>
                  </Link>
                </div>
                <div className={classNames("dropdown-divider")}></div>
                <div className=" ">
                  <Link
                    className={styles.link_css}
                    onClick={handleDomainChange.bind(
                      this,
                      "idea.appsdeployer.com"
                    )}
                  >
                    <div
                      className={classNames(
                        styles.dropdown_item,
                        "dropdown-item"
                      )}
                    >
                      <span className={classNames("text-muted", "text-sm")}>
                        <img
                          className={styles.switch_dots}
                          src={Idea}
                          alt="icon"
                        />
                      </span>
                      Ideadeployer
                    </div>
                  </Link>
                </div>
                <div className={classNames("dropdown-divider")}></div>
                <div className=" ">
                  <Link
                    className={styles.link_css}
                    onClick={handleDomainChange.bind(
                      this,
                      "risk.appsdeployer.com"
                    )}
                  >
                    <div
                      className={classNames(
                        styles.dropdown_item,
                        "dropdown-item"
                      )}
                    >
                      <span className={classNames("text-muted", "text-sm")}>
                        <img
                          className={styles.switch_dots}
                          src={Risk}
                          alt="icon"
                        />
                      </span>
                      Riskdeployer
                    </div>
                  </Link>
                </div>
                <div className={classNames("dropdown-divider")}></div>
                <div className=" ">
                  <Link
                    className={styles.link_css}
                    onClick={handleDomainChange.bind(
                      this,
                      "forms.appsdeployer.com"
                    )}
                  >
                    <div
                      className={classNames(
                        styles.dropdown_item,
                        "dropdown-item"
                      )}
                    >
                      <span className={classNames("text-muted", "text-sm")}>
                        <img
                          className={styles.switch_dots}
                          src={Form}
                          alt="icon"
                        />
                      </span>
                      Forms
                    </div>
                  </Link>
                </div>
                <div className={classNames("dropdown-divider")}></div>
                <div className=" ">
                  <Link
                    className={styles.link_css}
                    onClick={handleDomainChange.bind(
                      this,
                      "drive.appsdeployer.com"
                    )}
                  >
                    <div
                      className={classNames(
                        styles.dropdown_item,
                        "dropdown-item"
                      )}
                    >
                      <span className={classNames("text-muted", "text-sm")}>
                        <img
                          className={styles.switch_dots}
                          src={Drive}
                          alt="icon"
                        />
                      </span>
                      Drive
                    </div>
                  </Link>
                </div>
              </ul>
            </div>
            <div className={styles.profile_div}>
              <p className={styles.user_name}>
                {user?.userId?.username?.split(" ")[0]}
              </p>
              <p
                className={classNames(styles.user_image_icons)}
                onClick={handleClick}
                style={{ "--user-color": userColor }}
              >
                {user?.userId?.username
                  ?.split(" ")
                  .map((name) => name.charAt(0))
                  .join("")}
              </p>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <ListItemIcon onClick={handleLogout}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
