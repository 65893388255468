

import { API_URL,COOKIES_TOKEN } from "../../components/services/url";
import axios from "axios";

export const getUsers = callback => {
    return async dispatch => {
        try {
            const response = await axios.get(API_URL + `taskdeployer/users/getAllUsers`, {
                headers: {
                  "x-access-token": COOKIES_TOKEN,
                },
              });
            if (response.data.success === true) {
                dispatch({
                    type: 'ALL_USERS',
                    payload: response.data.AllUsersRole,
                });
                callback(response.data.AllUsersRole);
            }
        } catch (error) {
            callback(error)
        }
    };
};