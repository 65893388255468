import React, { useEffect, useState } from "react";
import { Html } from "react-konva-utils";

function getStyle(
  width,
  height,
  fontSize,
  italic,
  fontWeight,
  fontFamily,
  textDecoration,
  onColor,
) {

  const baseStyle = {
    width: `${width}px`,
    height: `${height}px`,
    fontSize: `${fontSize}px`,
    textAlign: "center",
    backgroundColor: "transparent",
    fontFamily: fontFamily,
    fontStyle: italic ? "italic" : "normal",
    fontWeight: fontWeight ? "bold" : "normal",
    textDecoration: textDecoration ? "underline" : "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    verticalAlign: "middle",
    horizontalAlign: "middle",
    outline: "none",
    color: onColor,
    border:"none",
    // padding: "10px",
    marginTop:"16px"
  };


  return baseStyle;
}


const  TextInput = React.memo(({
  x,
  y,
  width,
  height,
  value,
  onChange,
  fontSize,
  italic,
  textAlign,
  fontWeight,
  fontFamily,
  textDecoration,
  onColor,
  onSelect,
  onBlur,
}) =>{
  const style = getStyle(
    width,
    height,
    fontSize,
    italic,
    textAlign,
    fontWeight,
    fontFamily,
    textDecoration,
    onColor
  );
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event) => {
    let value = event.target.value;
    setInputValue(value);
    onChange(value);
  };

  return (
    <Html groupProps={{ x, y }}>
      <textarea
        value={inputValue}
        onChange={handleChange}
        onBlur={onBlur}
        style={style}
        onClick={onSelect}
        
      />
    </Html>
  );
})
export default TextInput;
