import React, { useState, useRef, useCallback } from "react";
import { Html } from "react-konva-utils";
import StickyStyle from "./StickyStyle";
import { BiRectangle } from "react-icons/bi";
import { Colors } from "../../utils/Colors";
import { useDispatch, useSelector } from "react-redux";
import {
  removeInFrames,
} from "../../Redux/actions/frame";
import {editStageTool} from "../../Redux/actions/tools"

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
  border: "2px solid black",
  borderRadius: "10px",
  gap: "10px",
  backgroundColor: Colors.WHITE,
  padding: "10px",
};

function StickyPopup({
  x,
  y,
  onClose,
}) {
  const colorOptions = [
    Colors.LIGHT_BLUE,
    Colors.LIGHT_GREEN,
    Colors.LIGHT_YELLOW,
  ];
  const dispatch = useDispatch();
  const [stylePopup, setStylePopup] = useState(false);
  const groupRef = useRef(null);


  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );

  const {  editToolData,editToolName } = useSelector(
    (store) => store.toolRoot
  );

  const handleColorClick = (color) => {
    let note = { ...editToolData, color: color };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  };

  const onIncreaseFontSize = (color) => {
    let newFontSize = editToolData?.fontSize+2
    let note = { ...editToolData, fontSize: newFontSize };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  };

  const onDecreaseFontSize = (color) => {
    let newFontSize= editToolData?.fontSize-2
    let note = { ...editToolData, fontSize: newFontSize };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  };


  return (
    <>
      <Html groupRef={groupRef} groupProps={{ x, y }} divProps={containerStyle}>
        <div style={containerStyle}>
          <span>
            <i
              className="fa fa-trash"
              onClick={useCallback(() =>
                dispatch(
                  removeInFrames(
                    selectedFrame?.boardId,
                    selectedFrame?._id,
                    editToolData,
                    editToolName
                  )
                )
              )}
            ></i>
          </span>

          <span>
            <i class="fa-sharp fa-regular fa-rectangle"></i>
          </span>
          <span>
            <i className="fa fa-plus" onClick={onIncreaseFontSize}></i>
          </span>
          <span>
            <i className="fa fa-minus" onClick={onDecreaseFontSize}></i>
          </span>

          <button
            onClick={() => setStylePopup(!stylePopup)}
            style={{
              border: "none",
              cursor: "pointer",
              background: "none",
              fontWeight: "bold",
            }}
          >
            T
          </button>
          {colorOptions.map((color, index) => (
            <span key={index}>
              <i
                className="fa fa-circle"
                style={{ color: color }}
                onClick={() => handleColorClick(color)}
              ></i>
            </span>
          ))}
          <span>
            <i className="fa fa-close" onClick={onClose}></i>
          </span>
        </div>
      </Html>
      {stylePopup && (
        <StickyStyle
          x={x + 105}
          y={y - 50}
        />
      )}
    </>
  );
}

export default StickyPopup;
