import { Rect, Transformer, Group } from "react-konva";
import React, { useEffect, useRef, useState } from "react";
import ShapeStyle from "./ShapeStyle";
import TextInput  from "./TextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  removeInFrames,
} from "../../../Redux/actions/frame";
import { editStageTool } from "../../../Redux/actions/tools";

 const Rectangle = React.memo(({ shapeProps }) => {
  // const [shapeProps, setShapeProps] = useState(props);

  // useEffect(() => {
  //   setShapeProps(props);
  // }, [props]);

  const shapeRef = useRef();
  const trRef = useRef();
  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );

  const { editToolName,editedToolId,editToolData,isEditToolSelected } = useSelector((store) => store.toolRoot);
  const [transform, setTransform] = useState(false);


  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);

  const handleDoubleClick = () => {
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: { isEditToolSelected: true, editToolName :"rectangle", editToolData: shapeProps,editedToolId: shapeProps.id },
    });
    setEditing(true);
    setTransform(true);

  };

  useEffect(() => {
    if (editing) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [editing]);

  useEffect(() => {
    // Remove transformer when selectedSquare.id changes
    if (shapeProps?.id !== editedToolId && editing) {
      setEditing(false);
    }
  }, [shapeProps, editedToolId, editing]);


  const handleBlur = (deleting) => {
    if (editToolData && Object.keys(editToolData).length > 0 && !deleting && !transform) {
    setEditing(false);
      dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData,editToolName));
  }
    // Additional logic for saving changes goes here
  };

  const onDragEnd = (x1, y1) => {
    dispatch(
      editStageTool(selectedFrame?.boardId,selectedFrame?._id, { ...editToolData, x: x1, y: y1 },editToolName)
    );
  };

  const handleChange = (value) => {
    setTransform(false);
    dispatch({
      type: "UPDATE_EDIT_SELECTED_TOOL",
      payload: { name:"text",value},
    });
  };

  return (
      <Group>
        <Rect
          onClick={() => handleDoubleClick()}
          ref={shapeRef}
          {...shapeProps}
          draggable
          stroke={0.5}
          onDragStart={handleDoubleClick}
          onDragEnd={(e) => onDragEnd(e.target.x(), e.target.y())}
          onTransformStart={handleDoubleClick}
          onTransformEnd={(e) => {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);

            dispatch(
              editStageTool(selectedFrame?.boardId,selectedFrame?._id, {
                ...editToolData,
                x: node.x(),
                y: node.y(),
                width: Math.max(5, node.width() * scaleX),
                height: Math.max(5, node.height() * scaleY),
              },
              editToolName)
            );
          }}
        />

        {editing && (
          <Transformer
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              if (newBox.width < 50 || newBox.height < 50) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}

        <TextInput
          editing={editing}
          x={shapeProps?.x + 20}
          y={shapeProps?.y + 20}
          value={shapeProps?.text}
          width={shapeProps?.width}
          height={shapeProps?.height}
          onBlur={()=>handleBlur(false)}
          onChange={(e)=>handleChange(e)}
          onSelect={handleDoubleClick}
        />

        {editing && (
          <ShapeStyle
            x={shapeProps.x}
            y={shapeProps.y - 60}
            onClose={() => setEditing(false)}
            onDelete={() =>
              dispatch(
                removeInFrames(
                  selectedFrame?.boardId,
                  selectedFrame?._id,
                  editToolData,
                  "rectangle"
                )
              )
            }
            onColorChange={(color) =>
              dispatch(
                editStageTool(selectedFrame?.boardId,selectedFrame?._id, {
                  ...editToolData,
                  fill: color,
                },editToolName
                )
              )
            }
          />
        )}
      </Group>
  
  );
});

export default Rectangle;
