import React, { useEffect, useState } from "react";
import { Html } from "react-konva-utils";

function getStyle(
  width,
  height,
  fontSize,
  italic,
  textAlign,
  fontWeight,
  fontFamily,
  textDecoration,
  textColor
) {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  const baseStyle = {
    width: `${width - 20}px`,
    height: `${height - 20}px`,
    border: "none",
    padding: "0px 10px",
    // marginLeft:'18px',
    background: "none",
    outline: "none",
    textDecoration: textDecoration ? "underline" : "none",

    color: textColor,
    fontSize: `${fontSize}px`,

    fontFamily: fontFamily,
    textAlign: textAlign,
    fontStyle: italic ? "italic" : "normal",
    fontWeight: fontWeight ? "bold" : "normal",
    resize: "none",
    overflow: "hidden",
  };
  if (isFirefox) {
    return baseStyle;
  }
  return {
    ...baseStyle,
  };
}

const  TextInput = React.memo(({
  x,
  y,
  width,
  height,
  value,
  onChange,
  fontSize,
  italic,
  textAlign,
  fontWeight,
  fontFamily,
  textDecoration,
  textColor,
  onSelect,
  onBlur,
}) => {
  const style = getStyle(
    width,
    height,
    fontSize,
    italic,
    textAlign,
    fontWeight,
    fontFamily,
    textDecoration,
    textColor,
  );
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    onChange(event.target.value);
  };
  return (
    <Html groupProps={{ x, y }} divProps={{ backgroundColor: "red" }}>
      <textarea
        value={inputValue}
        onChange={handleChange}
        onBlur={()=>onBlur()}
        style={style}
        onClick={onSelect}
      />
    </Html>
  );
});
export default TextInput;
