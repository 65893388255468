import axios from "axios";
import {
  getFrameRoute,
  addFrameRoute,
} from "../../components/services/apiRoute";
import { socket } from "../../components/services/url";

//sets current frame using index
export const getFrameById = (frameId, dynamicIndex) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(getFrameRoute + frameId);

      dispatch({
        type: "ALL_FRAME",
        payload: response.data.data,
      });

      // Adjust the index dynamically
      const adjustedIndex = dynamicIndex !== undefined ? dynamicIndex : 0;

      dispatch(setSelectedFrame(response.data.data[adjustedIndex]));
    } catch (error) {
      console.log("Error in fetch frame. ", error);
    }
  };
};

export const setSelectedFrame = (frameData) =>{
  return async (dispatch) => {
  dispatch({
    type: "SET_SELECTED_FRAME",
    payload: frameData,
  });
  }
  
}
export const addFrame = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(addFrameRoute, payload);
      dispatch({
        type: "ADD_FRAME",
        payload: response.data.data,
      });
    } catch (error) {
      console.log("Error in get all frame ", error);
    }
  };
};
export const deleteFrame = (boardId,frameId) => {
  return async () => {
    try {
      socket.emit("clear_frame_send", {boardId,frameId});
     
    } catch (error) {
      console.log("Error in get all frame ", error);
    }
  };
};
// Function for the select the frame
export const selectFrame = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_SELECTED_FRAME",
      payload: data,
    });
  };
};
// add in frames function
export const addInFrames = (boardId,frameId, data, type) => {
  
  return async () => {
    try {
 
      socket.emit('add_new_tool_send',{boardId,frameId,data,type})
    } catch (error) {
      console.log("Error in get all workspace ", error);
    }
  };
};



export const removeInFrames = (boardId,frameId, data, type) => {
  return async () => {
    try {
      socket.emit("remove_from_frame_send",{boardId,frameId,type,data})
    } catch (error) {
      console.log("Error in get all workspace ", error);
    }
  };
};

