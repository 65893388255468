import { Colors } from "./Colors";
export const DrawingColors = [
  { title: "Pig Pink", color: Colors.PIG_PINK },
  { title: "Blue", color: Colors.LIGHT_BLUE },
  { title: "Green", color: Colors.LIGHT_GREEN },
  { title: "Yellow", color: Colors.LIGHT_YELLOW },
  { title: "Magenta", color: Colors.MAGENTA },
  { title: "Cyan", color: Colors.CYAN },
  { title: "Orange", color: Colors.LIGHT_ORANGE },
  { title: "Purple", color: Colors.PURPLE },
  { title: "Begonia Pink", color: Colors.BEGONIA_PINK },
];
