export const toolIds = {
  STICKY: "stickyNotes",
  TEXT: "text",
  RECTANGLE: "rectangle",
  SQUARE: "square",
  CIRCLE: "circle",
  TRIANGLE: "triangle",
  HEXAGON: "hexagon",
  PENTAGON: "pentagon",
  ICON: "icon",
  PHOTOS: "photos",
  ARROW: "arrow",
  LINE: "line",
  LINE2: "line2",
  LINE3: "line3",
  ERASER: "eraser",
  BRUSH: "brush",
  ICON1: "icon1",
  ICON2: "icon2",
  ICON3: "icon3",
  ICON4: "icon4",
  BRUSH_ICON1: "brush_icon1",
  BRUSH_ICON2: "brush_icon1",
  BRUSH_ICON3: "brush_icon1",
  LINESHAPE: "lineShape",
};
