
import { socket} from "../../components/services/url";

export const editStageTool = (boardId,frameId, data,editToolName) => {
    return async () => {
      try {
        
          socket.emit("edit_frame_send", {
            frameId,
            boardId,
            data,
            type: editToolName,
          });
      } catch (error) {
        console.log("Error in edit in frame", error);
      }
    };
  };


  export const redoInFrame = (boardId,frameId, latestElementIntoRedoStack) => {
  
    const {type,data} = latestElementIntoRedoStack;
    return async () => {
      try {
        socket.emit("redo_from_frame_send",{boardId,frameId,data,type})
      } catch (error) {
        console.log("Error in redo frame data ", error);
      }
    };
  };
