import React, { useState } from "react";
import AnimatedCursor from "react-animated-cursor";
import { Colors } from "../../utils/Colors";

const AnimatedCursorPointer = () => {
  const [cursorClicked, setCursorClicked] = useState(false);
  const handleCursorClick = () => {
    setCursorClicked(true);
  };

  return (
    <AnimatedCursor
      onClick={handleCursorClick}
      trail={{
        selector: ".trailing-line",
        strokeWidth: 14,
        strokeColor: Colors.RED,
        trailLength: 1,
        trailOpacity: 0.2,
      }}
    />
  );
};

export default AnimatedCursorPointer;
