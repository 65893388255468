import React, { useCallback,useEffect} from "react";
import randomstring from "randomstring";
import {  useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createBoard, getAllBoard } from "../../Redux/actions/boards";
import { BsPlus } from "react-icons/bs";
import Navbar from "./navbar";
import BoardTable from "./boardTable";


const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
   dispatch( getAllBoard())
  }, []);
  const handleboard = useCallback(() => {
    let generatedBoardId = randomstring.generate({
      length: 20,
      charset: "alphabetic",
    });
    let generatedFrameId = randomstring.generate({
      length: 10,
      charset: "alphabetic",
    });
    try {
      let payload = {
        generatedFrameId,
        generatedBoardId,
        gridSize: Math.min(1500, 1200) / 8,
        stageWidth:1500,
        stageHeight:1200,
        zoomPercentage:100,
        scale :{ x: 1, y: 1 }
      };
      dispatch(
        createBoard(payload, async function (response) {
          if (response?.data?.data) {
            let boardId = response.data.data.boardData._id;
            navigate(`/drawing/${boardId}`);
          } else {
            navigate(`/`);
          }
        })
      );
    } catch (error) {
      navigate(`/`);
    }
  });

  return (
    <div style={{ backgroundColor: "white", height: "100vh" }}>
     <Navbar/>
      <div style={{ margin: "0 auto", maxWidth: "1140px", padding: "0 5px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            borderRadius: "10px",
            padding: "10px",
            borderRadius: "20px",
            border: "1.5px solid green",
          }}
          onClick={handleboard}
        >
          <p
            style={{
              backgroundColor: "green",
              borderRadius: "0.375rem",
              padding: "12px",
              color: "white",
              display: "flex",
              margin: "10px",
              cursor: "pointer",
            }}
          >
            {" "}
            <BsPlus size={20} />
          </p>
          <h2 style={{ marginLeft: "5px" }}>Start a new whiteboard</h2>
        </div>
      </div>{" "}
    <div style={{padding:"20px"}}>
    <BoardTable/>
    </div>
    </div>
  );
};

export default Dashboard;
