import { Circle, Transformer } from "react-konva";
import React, { useRef, useEffect, useState } from "react";
import CircleInput from "./circleInput";
import ShapeStyle from "./ShapeStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  removeInFrames,
} from "../../../Redux/actions/frame";
import { useCallback } from "react";
import {
  editStageTool
} from "../../../Redux/actions/tools";

const CircleShape = React.memo(({ shapeProps }) => {
    
  const shapeRef = useRef();
  const trRef = useRef();
  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );
  const { editToolName,editToolData } = useSelector((store) => store.toolRoot);

  const dispatch = useDispatch();
  const [editing, setEditing] = useState(shapeProps.id === editToolData?.id);
  const [transform, setTransform] = useState(false);
  useEffect(() => {
    if (editing) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [editing]);
  useEffect(() => {
    // Remove transformer when selectedSquare.id changes
    if (shapeProps.id !== editToolData?.id && editing) {
      setEditing(false);
    }
  }, [shapeProps.id, editToolData?.id, editing]);

  const handleDragEnd = () => {
    const node = shapeRef.current;
    const x = node.x();
    const y = node.y();
    const squareWidth = editToolData?.squareWidth;
    const squareX = x - squareWidth / 2;
    const squareY = y - squareWidth / 2;

    dispatch(
      editStageTool(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...editToolData,
          circleCenterX: node.x(),
          circleCenterY: node.y(),
          squareX:squareX,
          squareY:squareY,
        },
        editToolName
      )
    );
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const newSideLength = shapeProps.sideLength * scaleX;
    const newWidth = (2 * newSideLength )/Math.sqrt(2);
    const x = node.x() * scaleX;
    const y = node.y() * scaleY;
    const sqWidth = newSideLength;
    const sqX = x - sqWidth / 2;
    const sqY = y - sqWidth / 2;
    let data = {
      circleCenterX: x,
      circleCenterY: y,
      squareWidth: sqWidth,
      squareX: sqX,
      squareY: sqY,
      width: newWidth,
      sideLength: newSideLength,
    };
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: {
        isEditToolSelected: true,
        editToolName: "hexagon",
        editToolData: {
          ...editToolData,
          ...data,
        },
        editedToolId: shapeProps.id,
      },
    });
    dispatch(
      editStageTool(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...editToolData,
          ...data,
        },
        editToolName
      )
    );
  };

  const handleDoubleClick = () => {
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: { isEditToolSelected: true, editToolName :"circle", editToolData: shapeProps,editedToolId: shapeProps.id },
    });
    setEditing(true);
    setTransform(true);
  };
  const handleBlur = (deleting) => {
    if (editToolData && Object.keys(editToolData).length > 0 && !deleting && !transform) {
      setEditing(false);
        
        dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData,editToolName));
  }
  };

  const handleChange = (value) => {
    setTransform(false);

    dispatch({
      type: "UPDATE_EDIT_SELECTED_TOOL",
      payload: { name:"text",value},
    });
  };

  return (
    <React.Fragment>
      <Circle
        onClick={() => handleDoubleClick()}
        ref={shapeRef}
        {...shapeProps}
        draggable
        stroke={0.5}
        width={shapeProps?.width}
        x={shapeProps?.circleCenterX}
        y={shapeProps?.circleCenterY}
        onDragStart={handleDoubleClick}
        onTransformStart={handleDoubleClick}
        onDragEnd={handleDragEnd}
        onTransformEnd={(e) => handleTransformEnd(e)}
      />
      <CircleInput
        x={shapeProps.squareX}
        y={shapeProps.squareY}
        value={shapeProps.text}
        width={shapeProps.squareWidth}
        height={shapeProps.squareWidth}
        onBlur={()=>handleBlur(false)}
        onChange={(e)=>handleChange(e)}
        onSelect={handleDoubleClick}
      />

      {editing && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 150 || newBox.height < 150) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {editing && (
        <ShapeStyle
          x={shapeProps.circleCenterX}
          y={shapeProps.circleCenterY - (shapeProps.width / 2) - 60}
          onClose={() => setEditing(false)}
          onDelete={() =>
            dispatch(
              removeInFrames(
              selectedFrame?.boardId,
              selectedFrame?._id, 
              editToolData, "circle")
            )
          }
          onColorChange={(color) =>
            dispatch(
              editStageTool(
                selectedFrame?.boardId,
                selectedFrame?._id, {
                ...editToolData,
                fill: color,
              },editToolName)
            )
          }
        />
      )}
    </React.Fragment>
  );
})
export default CircleShape;
