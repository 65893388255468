import { API_URL,COOKIES_TOKEN } from "../../components/services/url";
import axios from "axios";

export const createBoard = (payload, callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(API_URL + `whiteboard/board/add`, payload, {
        headers: {
          "x-access-token": COOKIES_TOKEN,
        },
      });
      
      dispatch({
        type: "ADD_NEW_BOARD",
        payload: response.data.data.boardData,
      });
      dispatch({
        type: "ADD_NEW_FRAME",
        payload: response.data.data.frameData,
      });
      dispatch({
        type: "SET_SELECTED_BOARD",
        payload: response.data.data.boardData,
      });
      dispatch({
        type: "SET_SELECTED_FRAME",
        payload: response.data.data.frameData,
      });
      callback(response);
    } catch (error) {
      callback(error);
    }
  };
};

export const getAllBoard = () => {
 return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_URL}whiteboard/board/all`,
        {
          headers: {
            "x-access-token": COOKIES_TOKEN,
          },
        }
      );
      dispatch({
        type: "FETCH_ALL_BOARD",
        payload: response.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const userLogout = (callback) => {
  return async (dispatch) => {
    // dispatch({
    //   type: "CLEAR_BOARD",
    // });
    dispatch({
      type: "CLEAR_FRAME",
    });

    setTimeout(() => {
      localStorage.clear();
      // callback();
    }, 1000);
  };
};

export const getBoardById = (boardId) => {
  return async (dispatch) => {
     try {
       const response = await axios.get(
         `${API_URL}whiteboard/board/getBoardById/${boardId}`,
         {
           headers: {
             "x-access-token": COOKIES_TOKEN,
           },
         }
       );
       dispatch({
         type: "SET_SELECTED_BOARD",
         payload: response.data.data,
       });
     } catch (error) {
       console.log(error);
     }
   };
 };
export const shareBoard = (payload, boardId,callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        API_URL + `whiteboard/board/shareBoard/${boardId}`,payload, {
          headers: {
            "x-access-token": COOKIES_TOKEN,
          },
        }
      );
      if (response.data.success === true) {
        dispatch({
          type: "SET_SELECTED_BOARD",
          payload: response.data.data,
        });
        callback(true)
      }else{
        callback(false)
      } 
    } catch (error) {
      console.log("error in share board")
      callback(false)

    }
  };
};
