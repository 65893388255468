import React from "react";
import { Popover } from "react-bootstrap";
import { BsCircle, BsHexagon, BsPentagon, BsTriangle } from "react-icons/bs";
import { BiRectangle, BiSquare } from "react-icons/bi";
import { toolIds } from "../../../utils/ToolId";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../../utils/Colors";
import { MdOutlineMaximize } from "react-icons/md";

const ShapePopover = () => {

  const dispatch =useDispatch();
  const {
    selectedFrame,
  } = useSelector((store) => store.frameRoot);

  const handleAddShape = (shape) => {
  if (shape === toolIds.CIRCLE) {
      dispatch({
        type: "SET_TOOL_DATA",
        payload : {
          toolData: {
          width: 100,
          // height: 100,
          id: `cir${selectedFrame?.circle?.length + 1}`,
          },
          isToolSelected: true,
          selectToolName:shape,
      }
      })
    } else if (shape === toolIds.RECTANGLE) {
      dispatch({
        type: "SET_TOOL_DATA",
        payload : {
          toolData: {
            width: 150,
            height: 100,
            fill: Colors.WHITE,
            id: `rect${selectedFrame?.rectangle?.length + 1}`,
            text: "",
          },
          isToolSelected: true,
          selectToolName:shape,
      }
      })
    } else if (shape === toolIds.TRIANGLE) {
      dispatch({
        type: "SET_TOOL_DATA",
        payload : {
          toolData: {
            id: `tir${selectedFrame?.triangle?.length + 1}`,
            fill: Colors.WHITE
          },
          isToolSelected: true,
          selectToolName:shape,
      }
      })
    } else if (shape === toolIds.PENTAGON) {
      dispatch({
        type: "SET_TOOL_DATA",
        payload : {
          toolData: {
            id: `pen${selectedFrame?.pentagon?.length + 1}`,
            fill: Colors.WHITE,
          },
          isToolSelected: true,
          selectToolName:shape,
      }
      })
    } else if (shape === toolIds.HEXAGON) {
     
      dispatch({
        type: "SET_TOOL_DATA",
        payload : {
          toolData: {
          id: `hex${selectedFrame?.hexagon?.length + 1}`,
          fill: Colors.WHITE,
          text:"",
        },
          isToolSelected: true,
          selectToolName:shape,
      }
      })
    } else if (shape === toolIds.SQUARE) {
      dispatch({
        type: "SET_TOOL_DATA",
        payload : {
          toolData: {
            width: 150,
            height: 150,
            fill: Colors.WHITE,
            id: `sq${selectedFrame?.square?.length + 1}`,  
          },
          isToolSelected: true,
          selectToolName:shape,
      }
      })
    } else if (shape === toolIds.LINESHAPE) {
      dispatch({
        type: "SET_TOOL_DATA",
        payload : {
          toolData: {
            id: `line${selectedFrame?.lineShape?.length + 1}`,
            fill: Colors.WHITE
          },
          isToolSelected: true,
          selectToolName:shape,
      }
      })
    }
    document.body.style.cursor = "crosshair";
  };
 
  return (
    <Popover id="popover-basic">
      <Popover.Body>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>Shapes</h4>
          <span>
            <i className="fa fa-close" onClick={() => document.body.click()} />
          </span>
        </div>
        {/* options */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "300px", // Adjust the maximum width as per your requirement
            margin: "20px auto",
            gap: 20,
          }}
        >
          <BiSquare
            size={60}
            style={{ width: "60px" }} // Set the width of the icon
            onClick={() => {
              handleAddShape(toolIds.SQUARE);
              document.body.click();
            }}
          />

          <BiRectangle
            size={60}
            onClick={() => {
              handleAddShape(toolIds.RECTANGLE);
              document.body.click();
            }}
          />
          <BsCircle
            size={60}
            onClick={() => {
              handleAddShape(toolIds.CIRCLE);
              document.body.click();
            }}
          />
          <BsPentagon
            size={60}
            onClick={() => {
              handleAddShape(toolIds.PENTAGON);
              document.body.click();
            }}
          />
          <BsHexagon
            size={60}
            onClick={() => {
              handleAddShape(toolIds.HEXAGON);
              document.body.click();
            }}
          />
          <BsTriangle
            size={60}
            onClick={() => {
              handleAddShape(toolIds.TRIANGLE);
              document.body.click();
            }}
          />
          <MdOutlineMaximize 
           size={60}
           onClick={() => {
             handleAddShape(toolIds.LINESHAPE);
             document.body.click();
           }}
           />
        </div>
      </Popover.Body>
    </Popover>
  );
};

export default ShapePopover;
