import React from "react";
import InviteBoardInput from "./inviteBoardInput";
import styles from "./shareBoard.module.css";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { BsShareFill } from "react-icons/bs";
import { Colors } from "../../utils/Colors";

const ShareModal = ({ handleIconClick }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const openModal = () => {
    setOpenShareModal(true);
  };

  const handleClose = () => {
    setOpenShareModal(false);
  };
  return (
    <>
      <button
        className={styles.shareBtn}
        data-bs-toggle="modal"
        data-bs-target="#ShareModal"
        onClick={() => {
          setIsClicked(true);
          openModal();
        }}
      >
        <span>
          <BsShareFill
            size={22}
            title="Share"
            style={{
              cursor: "pointer",
              color: Colors.BLACK,
              padding: "2px",
              transition: "background-color 0.3s",
              backgroundColor: Colors.LIGHTGRAY,
              borderRadius: "4px",
            }}
            onClick={() => handleIconClick("icon5")}
          />
        </span>
      </button>

      <Modal
        id="ShareModal"
        centered
        show={openShareModal}
        size="md"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Board</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isClicked && <InviteBoardInput isClicked={isClicked} />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
