import { Group, Rect, Transformer } from "react-konva";
import React, { useRef, useEffect, useState } from "react";
import ShapeStyle from "./ShapeStyle";
import TextInput from "./TextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  removeInFrames,
} from "../../../Redux/actions/frame";

import {
  editStageTool
} from "../../../Redux/actions/tools";


const Square = React.memo(({ shapeProps }) => {

  const shapeRef = useRef();
  const trRef = useRef();
  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );
  const { editToolName,editedToolId,editToolData } = useSelector((store) => store.toolRoot);

  const dispatch = useDispatch();
  const [editing, setEditing] = useState(shapeProps.id === editToolData?.id);
  const [transform, setTransform] = useState(false);

  useEffect(() => {
    if (editing) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [editing]);

  useEffect(() => {
    // Remove transformer when selectedSquare.id changes
    if (shapeProps.id !== editToolData?.id && editing && !transform) {
      setEditing(false);
    }
  }, [shapeProps.id, editToolData?.id, editing]);


 
  const isTransformerEnabled = shapeProps.id === editToolData?.id;

  const onDragEnd = (x1, y1) => {
    dispatch(
      editStageTool(selectedFrame?.boardId,selectedFrame?._id, { ...editToolData, x: x1, y: y1 },editToolName)
    );
  };

  const handleDoubleClick = () => {
    console.log("on double click")
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: { isEditToolSelected: true, editToolName :"square", editToolData: shapeProps,editedToolId: shapeProps.id },
    });
    setEditing(true);
    setTransform(true);
  };
  const handleBlur = (deleting) => {
    if (editToolData && Object.keys(editToolData).length > 0 && !deleting && !transform) {
    setEditing(false);
      console.log("Call",editToolData)
      dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData,editToolName));
  }
  };

  const handleChange = (value) => {
    setTransform(false);
    dispatch({
      type: "UPDATE_EDIT_SELECTED_TOOL",
      payload: { name:"text",value},
    });
  };
  return (
    <React.Fragment>
      <Rect
        onClick={() => {
          handleDoubleClick();
        }}
        ref={shapeRef}
        {...shapeProps}
        draggable
        stroke={0.5}
        onDragStart={handleDoubleClick}
        onDragEnd={(e) => onDragEnd(e.target.x(), e.target.y())}
        onTransformStart={handleDoubleClick}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);
          dispatch(
            editStageTool(selectedFrame?.boardId,
              selectedFrame?._id, {
              ...editToolData,
              x: node.x(),
              y: node.y(),
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(5, node.height() * scaleY),
            },editToolName)
          );
        }}
        transformEnable={isTransformerEnabled}
      />
      <TextInput
        editing={editing}
        x={shapeProps.x + 20}
        y={shapeProps.y + 20}
        value={shapeProps.text}
        width={shapeProps.width}
        height={shapeProps.height}
        onBlur={()=>handleBlur(false)}
        onChange={(e)=>handleChange(e)}
        onSelect={handleDoubleClick}
      />
      {editing && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 150 || newBox.height < 150) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {editing && (
        <ShapeStyle
          x={shapeProps.x}
          y={shapeProps.y - 60}
          onDelete={() =>
            dispatch(
              removeInFrames(selectedFrame?.boardId,selectedFrame?._id, editToolData, "square")
            )
          }
          onClose={() => setEditing(false)}
          onColorChange={(color) =>{
            dispatch(
              editStageTool(selectedFrame?.boardId,selectedFrame?._id, {
                ...editToolData,
                fill: color,
              },editToolName)
            )
          }
        }
        />
      )}
    </React.Fragment>
  );
});
export default Square;
