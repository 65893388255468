import { RegularPolygon, Transformer,Rect,Text } from "react-konva";
import React, { useRef, useEffect, useState } from "react";
import ShapeStyle from "./ShapeStyle";
import { useDispatch, useSelector } from "react-redux";
import { removeInFrames } from "../../../Redux/actions/frame";
import {
  editStageTool
} from "../../../Redux/actions/tools";
import TextInput from "./triangleInput";


const Triangle = React.memo(({ shapeProps }) => {

  const shapeRef = useRef();
  const trRef = useRef();
  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );
  const { editToolName,editToolData } = useSelector((store) => store.toolRoot);

  const dispatch = useDispatch();
  const [editing, setEditing] = useState(shapeProps.id === editToolData?.id);
  const [transform, setTransform] = useState(false);
  useEffect(() => {
    if (editing) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [editing]);
  
  useEffect(() => {
    // Remove transformer when selectedSquare.id changes
    if (shapeProps.id !== editToolData?.id && editing) {
      setEditing(false);
    }
  }, [shapeProps.id, editToolData?.id, editing]);

  const handleDragEnd = () => {
    const node = shapeRef.current;
    const x = node.x();
    const y = node.y();
    const recWidth = editToolData?.sideLength / Math.sqrt(6);
    const recHeight = editToolData?.sideLength / Math.sqrt(6);
    const recX = x - recWidth / 2;
    const recY = y - recHeight / 2;
    dispatch(
      editStageTool(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...editToolData,
          triangleCenterX: node.x(),
          triangleCenterY: node.y(),
          rectangleX: recX,
          rectangleY: recY,
        },
        editToolName
      )
    );
  };

  const handleDoubleClick = () => {
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: { isEditToolSelected: true, editToolName :"triangle", editToolData: shapeProps,editedToolId: shapeProps.id },
    });
    setEditing(true);
    setTransform(true);
  };
  const handleBlur = (deleting) => {
    if (editToolData && Object.keys(editToolData).length > 0 && !deleting && !transform) {
    setEditing(false);
      dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData,editToolName));
  }
  };

  const handleChange = (value) => {
    setTransform(false);
    dispatch({
      type: "UPDATE_EDIT_SELECTED_TOOL",
      payload: { name:"text",value},
    });
  };
  const handleTransformEnd = () => {
    const node = shapeRef.current;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const newSideLength = shapeProps.sideLength * scaleX;

    const newWidth = (3 * newSideLength)/Math.sqrt(3);
    const x = node.x() * scaleX;
    const y = node.y() * scaleY;
    const recWidth = newSideLength / Math.sqrt(6);
    const recHeight = newSideLength / Math.sqrt(6);
    const recX = x - recWidth / 2;
    const recY = y - recHeight / 2;
    let data = {
      triangleCenterX: x,
      triangleCenterY: y,
      rectangleWidth: recWidth,
      rectangleHeight: recHeight,
      rectangleX: recX,
      rectangleY: recY,
      width: newWidth,
      sideLength: newSideLength,
      radius: newSideLength / 2,
    };
    dispatch(
      editStageTool(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...editToolData,
          ...data,
        },
        editToolName
      )
    );
  };
  return (
    <>
      <RegularPolygon
        onClick={() => handleDoubleClick()}
        ref={shapeRef}
        {...shapeProps}
        draggable={true}
        stroke={0.5}
        radius={shapeProps.radius}
        x={shapeProps?.triangleCenterX}
        y={shapeProps?.triangleCenterY}
        sides={3} // Set the number of sides to 3 for a triangle
        onDragEnd={handleDragEnd}
        onTransformStart={handleDoubleClick}
        onDragStart={handleDoubleClick}
        onTransformEnd={handleTransformEnd}
      />
       <Rect
        x={shapeProps?.rectangleX}
        y={shapeProps?.rectangleY}
        width={shapeProps?.rectangleWidth}
        height={shapeProps?.rectangleHeight}
        id="triangle"
        draggable
        onClick={handleDoubleClick}
      />
      <Text
        fontSize={15}
        fill="white"
        x={shapeProps?.rectangleX}
        y={shapeProps?.rectangleY}
        width={shapeProps?.rectangleWidth}
        height={shapeProps?.rectangleHeight}
        align="center"
        verticalAlign="middle"
        id="triangleId"
        onClick={handleDoubleClick}
        ref={trRef}
      />
      <TextInput
           editing={editing}
           x={shapeProps?.rectangleX}
           y={shapeProps?.rectangleY}
           value={shapeProps?.text}
           width={shapeProps?.rectangleWidth}
           height={shapeProps?.rectangleHeight}
        onBlur={() => handleBlur(false)}
        onChange={(e) => handleChange(e)}
        onSelect={handleDoubleClick}
      />
      {editing
       && (
        <ShapeStyle
        x={shapeProps.triangleCenterX}
        y={shapeProps.triangleCenterY - (shapeProps.width / 2) - 40}
          onDelete={() =>
            dispatch(
              removeInFrames(selectedFrame?.boardId,selectedFrame?._id, editToolData, "triangle")
            )
          }
          onColorChange={(color) =>
            dispatch(
              editStageTool(
                selectedFrame?.boardId,
                selectedFrame?._id, {
                ...editToolData,
                fill: color,
              },editToolName)
            )
          }
        />
      )}
      {editing && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 50 || newBox.height < 50) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
})
export default Triangle;