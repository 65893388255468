import React, { useState, useRef } from "react";
import { Html } from "react-konva-utils";

import { BiRectangle } from "react-icons/bi";
import { Colors } from "../../../utils/Colors";
import { BsTrash } from "react-icons/bs";

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
  border: "2px solid black",
  borderRadius: "10px",
  gap: "10px",
  backgroundColor: Colors.WHITE,
  padding: "10px",
};

function ShapeStyle({
  x,
  y,
  onClose,
  onColorChange,
  onLock,
  onDelete
}) {
  const colorOptions = [
    Colors.LIGHT_BLUE,
    Colors.LIGHT_GREEN,
    Colors.LIGHT_YELLOW,
  ];

  const [stylePopup, setStylePopup] = useState(false);
  const [lock, setUnlock] = useState(true);
  const groupRef = useRef(null);
  const handleLock = () => {
    setUnlock(!lock);
    onLock();
  };

  const handleColorClick = (color) => {
    onColorChange(color);
  };

  return (
    <>
      <Html groupRef={groupRef} groupProps={{ x, y }} divProps={containerStyle}>
        <div style={containerStyle}>
         <span> <BsTrash onClick={()=>onDelete()} /></span>

          {colorOptions.map((color, index) => (
            <span key={index}>
              <i
                className="fa fa-circle"
                style={{ color: color }}
                onClick={() => handleColorClick(color)}
              />
            </span>
          ))}
          <span>
            <i className="fa fa-close" onClick={onClose} />
          </span>
        </div>
      </Html>
    </>
  );
}

export default ShapeStyle;
