import { API_URL } from "./url";
export const BASE_URL =`${API_URL}whiteboard/`;
// export const BASE_URL = `${API_URL}whiteboard/`;

export const getFrameRoute = BASE_URL + "frame/all/";
export const addFrameRoute = BASE_URL + "frame/add";
export const deleteFrameRoute = BASE_URL + "frame/delete/";
export const clearFrameRoute = BASE_URL + "frame/clearFrame/";
export const addInFrame = BASE_URL + "frame/addInFrame";
export const updateFrame = BASE_URL + "frame/gridUpdateInFrame";
export const deleteInFrame = BASE_URL + "frame/deleteInFrame";
export const updateInFrame = BASE_URL + "frame/editInFrame";
export const redoFrame = BASE_URL + "frame/redoFrame";

