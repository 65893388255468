import { Group, Rect, Transformer } from "react-konva";
import React, { useRef, useEffect, useState } from "react";
import ShapeStyle from "../StickyPopup";
import TextInput from "./TextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  removeInFrames,
} from "../../../Redux/actions/frame";

import {
  editStageTool
} from "../../../Redux/actions/tools";


const TextNotes = React.memo(({ textNote }) => {

  const shapeRef = useRef();
  const trRef = useRef();
  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );
  const { editToolName,editedToolId,editToolData } = useSelector((store) => store.toolRoot);

  const dispatch = useDispatch();
  const [editing, setEditing] = useState(textNote?.id === editToolData?.id);
  const [transform, setTransform] = useState(false);

  useEffect(() => {
    if (editing) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [editing]);

  useEffect(() => {
    if (textNote?.id !== editToolData?.id && editing && !transform) {
      setEditing(false);
    }
  }, [textNote?.id, editToolData?.id, editing]);


 
//   const isTransformerEnabled = textNote?.id === editToolData?.id;

  const onDragEnd = (x1, y1) => {
    dispatch(
      editStageTool(selectedFrame?.boardId,selectedFrame?._id, { ...editToolData, x: x1, y: y1 },editToolName)
    );
  };

  const handleDoubleClick = () => {
    console.log("on double click")
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: { isEditToolSelected: true, editToolName :"text", editToolData: textNote,editedToolId: textNote?.id },
    });
    setEditing(true);
    setTransform(true);
  };
  const handleBlur = (deleting) => {
    if (editToolData && Object.keys(editToolData).length > 0 && !deleting && !transform) {
    setEditing(false);
      console.log("Call",editToolData)
      dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData,editToolName));
  }
  };

  const handleChange = (value) => {
    setTransform(false);
    dispatch({
      type: "UPDATE_EDIT_SELECTED_TOOL",
      payload: { name:"text",value},
    });
  };
  console.log("textNote",textNote)
  return (
    <React.Fragment>
      <Rect
        onClick={() => {
          handleDoubleClick();
        }}
        ref={shapeRef}
        {...textNote}
        fill={textNote?.color}
        draggable
        stroke={0.1}
        onDragStart={handleDoubleClick}
        onDragEnd={(e) => onDragEnd(e.target.x(), e.target.y())}
        onTransformStart={handleDoubleClick}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          dispatch(
            editStageTool(selectedFrame?.boardId,
              selectedFrame?._id, {
              ...editToolData,
              x: node.x(),
              y: node.y(),
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(5, node.height() * scaleY),
            },editToolName)
          );
        }}
        // transformEnable={isTransformerEnabled}
      />
      <TextInput
        editing={editing}
        x={textNote?.x + 20}
        y={textNote?.y + 20}
        value={textNote?.text}
        width={textNote?.width}
        height={textNote?.height}
        fontSize={textNote.fontSize}
        italic={textNote.isItalic}
        textAlign={textNote?.textAlign}
        textColor={textNote?.textColor}
        fontWeight={textNote?.fontWeight}
        fontFamily={textNote.fontFamily}
        textDecoration={textNote.textDecoration}
        onBlur={()=>handleBlur(false)}
        onChange={(e)=>handleChange(e)}
        onSelect={handleDoubleClick}
      />
      {editing && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 150 || newBox.height < 150) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {editing && (
        <ShapeStyle
          x={textNote?.x}
          y={textNote?.y - 60}
          onDelete={() =>
            dispatch(
              removeInFrames(selectedFrame?.boardId,selectedFrame?._id, editToolData, "text")
            )
          }
          onClose={() => setEditing(false)}
          onColorChange={(color) =>{
            dispatch(
              editStageTool(selectedFrame?.boardId,selectedFrame?._id, {
                ...editToolData,
                fill: color,
              },editToolName)
            )
          }
        }
        />
      )}
    </React.Fragment>
  );
});
export default TextNotes;
