import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch } from "react-redux";
import axios from "axios";
import { COOKIES_TOKEN } from "../services/url";
import { API_URL } from "../services/url";
import { getBoardById } from "../../Redux/actions/boards";

const BoardTable = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState(null); // Track the selected board for the popup
  const { boardData } = useSelector((state) => state.boardRoot);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBoardClick = (board) => {
    // Redirect to the whiteboard page with the selected board ID
    dispatch(getBoardById(board._id));
    navigate(`/drawing/${board?._id}`);
  };

  const toggleOptions = (boardId) => {
    setShowOptions(!showOptions);
    setSelectedBoardId(boardId);
  };

  const handleDeleteRow = async (boardId) => {
    try {
      // Make API call to delete the board
      await axios.delete(`${API_URL}whiteboard/board/delete/${boardId}`, {
        headers: {
          "x-access-token": COOKIES_TOKEN,
        },
      });
      dispatch({
        type: "DELETE_BOARD",
        payload: boardId,
      })
    } catch (error) {
      console.error("Error deleting board:", error);
    }
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  };

  const cellStyle = {
    border: "1px solid #ddd",
    padding: "15px",
    textAlign: "left",
  };

  const headerStyle = {
    backgroundColor: "#a4a6a4",
    color: "#333", // Text color for the header
  };

  const rowStyle = {
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Box shadow for each row
    marginBottom: "10px", // Margin bottom between rows
    transition: "box-shadow 0.3s ease-in-out", // Smooth transition for box shadow
    cursor: "pointer",
    position: "relative", // Make the position relative for absolute positioning of the icon
  };

  const iconStyle = {
    position: "absolute", // Position the icon absolutely within the relative container
    top: "50%", // Center vertically
    right: "10px", // Adjust the distance from the right edge
    transform: "translateY(-50%)", // Center vertically using translateY
    cursor: "pointer",
  };

  const Popup = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "70%",
          right: "20px",
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "5px",
          padding: "0 10px",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <p onClick={() => handleDeleteRow(selectedBoardId)}>Delete</p>
        {/* Add more options as needed */}
      </div>
    );
  };
  return (
    <div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={{ ...cellStyle, ...headerStyle }}>Board No.</th>
            <th style={{ ...cellStyle, ...headerStyle }}>Board Id</th>
            <th style={{ ...cellStyle, ...headerStyle }}>Created By</th>
            <th style={{ ...cellStyle, ...headerStyle }}></th>
          </tr>
        </thead>
        <tbody>
          {boardData &&
            Array.isArray(boardData) &&
            boardData.map((board, index) =>
              board ? (
                <tr key={board.boardNumber} style={{ ...rowStyle }}>
                  <td style={cellStyle} onClick={() => handleBoardClick(board)}>
                    {index + 1}
                  </td>
                  <td style={cellStyle} onClick={() => handleBoardClick(board)}>
                    {board._id}
                  </td>
                  <td style={cellStyle} onClick={() => handleBoardClick(board)}>
                    {board?.createdByName}
                  </td>
                  <td
                    style={{ position: "relative" }}
                    onClick={() => toggleOptions(board._id)}
                  >
                    <HiDotsVertical style={iconStyle} />
                    {showOptions && selectedBoardId === board._id && <Popup />}
                  </td>
                </tr>
              ) : null
            )}
        </tbody>
      </table>
    </div>
  );
};

export default BoardTable;
