import axios from "axios";
import jwtDecode from "jwt-decode";
import { COOKIES_TOKEN } from "../../components/services/url";
import { API_URL } from "../../components/services/url";
import Cookies from "js-cookie";

export const userLogin = (userLoginCredentials) => {
  return async () => {
    try {
      const { data } = await axios({
        method: "Post",
        url: `${API_URL}/deployer/login`,
        data: userLoginCredentials,
      });
      if (data.success) {
        const { drivetoken } = data;
        if (data) {
          Cookies.set("account_token", drivetoken);
          window.location.reload();
        }
      }
    } catch (err) {
      if (err?.response) {
        alert(err?.response?.data?.message);
      }
    }
  };
};

export const userLoginHelper = (data) => {
  return (dispatch) => {
    // Decode the token

    const decodedToken = jwtDecode(COOKIES_TOKEN);
    // Dispatch an action to set the user data and decoded token
    dispatch({
      type: "SET_USERS_DATA",
      payload: decodedToken?._doc,
    });
  };
};

export const userLogout = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
  };
};
