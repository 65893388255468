import React, { useState } from "react";
import { Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeading, faParagraph } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { toolIds } from "../../../utils/ToolId";


const TextPopover = () => {
  const [selectedIcon, setSelectedIcon] = useState(null);
  const dispatch= useDispatch();
  const { selectedFrame } = useSelector((store) => store.frameRoot);

  const handleIconClick = (icon) => {
    setSelectedIcon(icon === selectedIcon ? null : icon);
  };

  const handleAddText = (fontSize, w, h) => {
 
    dispatch({
      type: "SET_TOOL_DATA",
      payload : {
        toolData: {
          id: selectedFrame?.text?.length + 1,
          text: "Click to resize. Double click to edit.",
          width: w,
          height: h,
          fontSize: fontSize,
          selected: false,
        },
        isToolSelected: true,
        selectToolName: toolIds.TEXT,
    }
    })
  };

  return (
    <Popover id="popover-basic">
      <Popover.Body>
        <div>
          <button
            onClick={() => handleAddText(20, 200, 200)}
            className={`btn btn-link ${
              selectedIcon === "normal" ? "selected" : ""
            }`}
          >
            <FontAwesomeIcon
              title="Paragraph"
              icon={faParagraph}
              color={selectedIcon === "normal" ? "red" : ""}
              onClick={() => handleIconClick("normal")}
            />{" "}
          </button>
          <button
            onClick={() => handleAddText(40, 400, 200)}
            className={`btn btn-link ${
              selectedIcon === "heading" ? "selected" : ""
            }`}
          >
            <FontAwesomeIcon
              title="Heading"
              icon={faHeading}
              size={200}
              color={selectedIcon === "heading" ? "red" : ""}
              onClick={() => handleIconClick("heading")}
            />
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );
};

export default TextPopover;
