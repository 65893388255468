import React, { useEffect, useState } from "react";
import { Popover } from "react-bootstrap";
import { HiOutlinePencil } from "react-icons/hi";
import { GoPencil } from "react-icons/go";
import { ImPencil } from "react-icons/im";
import { Colors } from "../../utils/Colors";
import { DrawingColors } from "../../utils/DrawingColor";
import { BsEraser } from "react-icons/bs";
import { toolIds } from "../../utils/ToolId";
import styles from "./popover.module.css";
import { useDispatch, useSelector } from "react-redux";

const CustomPopover = () => {
  const [highlighted, setHighlighted] = useState(false);
  const dispatch = useDispatch();
  const { penstroke } = useSelector((store) => store.frameRoot);
  const { selectToolName } = useSelector((store) => store.toolRoot);

  const [progressWidth, setProgressWidth] = useState(penstroke?.width?penstroke?.width*10:10); // Initial width

  const handleStrokeClick = (event) => {
    const progressBar = event.currentTarget;
    const clickX = event.clientX - progressBar.getBoundingClientRect().left;
    const newWidth = (clickX / progressBar.clientWidth) * 100;

    // Update the state with the new width
    setProgressWidth(newWidth);
    let width = Math.ceil(newWidth / 10) <= 1 ? 1 : Math.ceil(newWidth / 10);
    let strockValue = selectToolName === toolIds.LINE2? 2 * width : selectToolName === toolIds.LINE3 ? 3* width: 1* width
    dispatch({
      type: "UPDATE_STROKE",
      payload: {
        key: "width",
        value: width,
      },
    });
    dispatch({
      type: "UPDATE_STROKE",
      payload: {
        key: "strokeWidth",
        value: strockValue,
      },
    });
  };

  const handleColorChange = (color) => {
    dispatch({
      type: "UPDATE_STROKE",
      payload: {
        key: "stroke",
        value: color,
      },
    });
  };
  const handleSelectedTool = (toolName) => {
    dispatch({
      type: "SET_SELECTED_TOOLNAME",
      payload: toolName,
    });
    dispatch({
      type: "UPDATE_STROKE",
      payload: {
        key: "strokeWidth",
        value:
          toolName === toolIds.LINE2 ?penstroke?.width* 2: toolName === toolIds.LINE3 ? penstroke?.width* 3: penstroke?.width*1,
      },
    });
  };
  const handleSelectEraser = (toolName) => {
    dispatch({
      type: "SET_SELECTED_TOOLNAME",
      payload: toolName,
    });
    dispatch({
      type:"UPDATE_PENSTROKE",
      payload:{
        width:2,
        stroke: "white",
        strokeWidth: 10
      }
    })
  }
  const handleClick = (iconName) => {
    setHighlighted(iconName);
  };


  useEffect(() => {
    setProgressWidth(penstroke?.width?penstroke?.width*10:10);
  },[penstroke?.width])

  return (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className={styles.color_container}>
          <div
            className={`${styles.progress_bar_container}`}
            onClick={(e) => handleStrokeClick(e)}
          >
            <div
              className={styles.progressBar}
              style={{ width: `${progressWidth}%` }}
            />
          </div>
          {DrawingColors.map((color, index) => (
            <div
              key={index}
              onClick={() => {
                handleColorChange(color.color);
              }}
              className={`${styles.color_option} ${
                penstroke?.stroke === color.color
                  ? styles.selected_icon_color
                  : ""
              }`}
              style={{ "--color": color.color }}
              title={color.title}
            />
          ))}
        </div>
        <div className={styles.tool_container}>
          <div
            onClick={() => {
              handleSelectedTool(toolIds.LINE);
            }}
            className={`${styles.tool_option} ${
              penstroke?.stroke ? styles.selectedColor : ""
            }`}
            style={{ "--color": Colors.BLACK }}
          >
            <div
              onClick={() => handleSelectedTool(toolIds.LINE)}
              className={styles.tool_icon}
            >
              <HiOutlinePencil
                size={22}
                title="Pen"
                className={`${styles.tool_icon} ${
                  highlighted === toolIds.ICON1 ? styles.highlighted_icon : ""
                }`}
                onClick={() => handleClick(toolIds.ICON1)}
              />
            </div>
          </div>
          <div
            onClick={() => {
              handleSelectedTool(toolIds.LINE2);
            }}
            className={`${styles.tool_option} ${
              penstroke?.stroke ? styles.selectedColor : ""
            }`}
            style={{ "--color": Colors.BLACK }}
          >
            <GoPencil
              size={22}
              title="Marker"
              className={`${styles.tool_icon} ${
                highlighted === toolIds.ICON2 ? styles.highlighted_icon : ""
              }`}
              onClick={() => handleClick(toolIds.ICON2)}
            />
          </div>
          <div
            onClick={() => {
              handleSelectedTool(toolIds.LINE3);
            }}
            className={`${styles.tool_option} ${
              penstroke?.stroke ? styles.selectedColor : ""
            }`}
            style={{ "--color": Colors.BLACK }}
          >
            <ImPencil
              size={22}
              title="Highlighter"
              className={`${styles.tool_icon} ${
                highlighted === toolIds.ICON3 ? styles.highlighted_icon : ""
              }`}
              onClick={() => handleClick(toolIds.ICON3)}
            />
          </div>
          <div
            onClick={() => handleSelectEraser(toolIds.ERASER)}
            className={styles.eraser_option}
          >
            <BsEraser
              size={22}
              title="Eraser"
              className={`${styles.tool_icon} ${
                highlighted === toolIds.ICON4 ? styles.highlighted_icon : ""
              }`}
              onClick={() => handleClick(toolIds.ICON4)}
            />
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );
};

export default CustomPopover;
