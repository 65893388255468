import React from "react";
import { Colors } from "../../../utils/Colors";
import {  FaRegHandPaper } from "react-icons/fa";
import { GoScreenFull, GoScreenNormal } from "react-icons/go";
import styles from "../drawingArea.module.css";
import { useDispatch, useSelector } from "react-redux";


const BottumTool = ({handleFullScreen,toggleFullScreen,
setIsDraggable,isDraggable,setIsMoveMode,isMoveMode,isFullScreen}) =>{

  const dispatch = useDispatch();

  const handleIconClick = (icon) =>{
    dispatch({
      type:"SET_SELECTED_ICON",
      payload:icon
    })
  }
  const {
    selectedIcon,
  } = useSelector((store) => store.toolRoot);
    return (
        <div className={styles.fullscreen_main_container}>
            <div className={styles.fullscreen_container}>
              <div
                onClick={handleFullScreen}
                className={`${selectedIcon === "icon8" ? styles.icon_button_icon1 : ""
                  }`}
              >
                {isFullScreen ? (
                  <GoScreenNormal
                    size={20}
                    title="Normal Screen"
                    onClick={() => {
                      handleIconClick("icon8");
                      toggleFullScreen();
                    }}
                  />
                ) : (
                  <GoScreenFull
                    size={20}
                    title="Full Screen"
                    onClick={() => {
                      handleIconClick("icon8");
                      toggleFullScreen();
                    }}
                  />
                )}
              </div>
              <div className={styles.nav_container}>
                <FaRegHandPaper
                  size={22}
                  title="Move Mode"
                  style={{
                    cursor: "pointer",
                    color: Colors.BLACK,
                    padding: "2px",
                    transition: "background-color 0.3s",
                    ...(selectedIcon === "icon9" && {
                      backgroundColor: Colors.LIGHTGRAY,
                      borderRadius: "4px",
                    }),
                  }}
                  onClick={() => {
                    setIsDraggable(!isDraggable);
                    setIsMoveMode(!isMoveMode);
                    handleIconClick("icon9");
                    setTimeout(() => {
                      setIsMoveMode(false);
                    }, 2000);
                  }}
                />
              </div>
              {/* Popup message */}
             
                <div
                  style={{
                    position: "fixed",
                    bottom: "50px",
                    right: "5px",
                    backgroundColor: Colors.GRAY,
                    padding: "6px",
                    borderRadius: "4px",
                    zIndex: 1,
                    opacity: isMoveMode ? 1 : 0,
                    transition: "opacity 0.1s",
                  }}
                >
                  {isMoveMode
                    ? "You have entered move mode."
                    : "You have exited move mode."}
                </div>
            
            </div>
          </div>
    )
}
export default BottumTool;