export const Colors = {
  RED: "#FF0000",
  GREEN: "#00FF00",
  BLUE: "#0000FF",
  YELLOW: "#FFFF00",
  MAGENTA: "#FF00FF",
  CYAN: "#00FFFF",
  ORANGE: "#FFA500",
  PURPLE: "#800080",
  DARK_GREEN: "#008000",
  BLACK: "#000000",
  GRAY: "#494848",
  LIGHTGRAY: "#d8d7d8",
  MEDIUMGRAY: "#6f7c80",
  WHITE: "#FFFFFF",
  PUREWHITE: "#FFFFFF",
  SKYBLUE: "#0055ff",
  LIGHT_YELLOW: "#fff9b1",
  LIGHT_BLUE: "#67C6C0",
  LIGHT_ORANGE: "#ff9d48",
  DARK_YELLOW: "#f5d128",
  LIGHT_GREEN: "#c9df56",
  PIG_PINK: "#ffcee0",
  BEGONIA_PINK: "#ea94bb",
};
