import React from "react";
import { Popover } from "react-bootstrap";
import {
  BsStickiesFill,
} from "react-icons/bs";
import { DrawingColors } from "../../utils/DrawingColor";
import { toolIds } from "../../utils/ToolId";
import styles from "./customStickyPopover.module.css";
import { useDispatch, useSelector } from "react-redux";


const CustomStickyPopover = () => {
  const { selectedFrame } = useSelector((store) => store.frameRoot);
  const dispatch= useDispatch();

  const handleAddNote = (w, h, stickyColor) => {
    dispatch({
      type: "SET_TOOL_DATA",
      payload : {
        toolData: {
          id: `${selectedFrame?._id}_${selectedFrame?.stickyNotes?.length + 1}`,
          width: w,
          height: h,
          text: "",
          draggable: true,
          color: stickyColor,
          fontSize: 50,
        },
        isToolSelected: true,
        selectToolName: toolIds.STICKY,
    }
    })
    document.body.style.cursor = "crosshair";
  };
  return (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className={styles.container}>
          <h4>Sticky Notes</h4>
          <span>
            <i
              className="fa fa-close"
              onClick={() => document.body.click()}
            ></i>
          </span>
        </div>
          <div className={styles.noteContainer}>
            {DrawingColors.map((color) => (
              <div key={color.color}>
                <BsStickiesFill
                  size={50}
                  fill={color.color}
                  color={color.color}
                  title="Sticky Note"
                  onClick={() =>
                    handleAddNote(200, 250, color.color)
                  }
                />
              </div>
            ))}
          </div>
       
      </Popover.Body>
    </Popover>
  );
};
export default CustomStickyPopover;
