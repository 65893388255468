import { React, useState, useCallback } from "react";
import {
  BsCameraVideo,
  BsThreeDots,
  BsEmojiSunglasses,
  BsBell,
  BsTrashFill,
  BsDownload,
  BsZoomIn,
  BsZoomOut,
  BsMoonFill,
  BsSunFill,
  BsShareFill,
  BsFillPlusCircleFill,
} from "react-icons/bs";
import { FaRedo, FaUndoAlt } from "react-icons/fa";
import { HiOutlinePhotograph } from "react-icons/hi";
import { VscCommentDiscussion } from "react-icons/vsc";
import { GiAlarmClock, GiHamburgerMenu } from "react-icons/gi";
import { GoScreenFull, GoScreenNormal } from "react-icons/go";
import { BiFolder } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Timer from "./Timer";
import BoardPopover from "./BoardPopover";
import { Colors } from "../../utils/Colors";
import {
  addFrame,
  deleteFrame,
  removeInFrames,
} from "../../Redux/actions/frame";
import { redoInFrame } from "../../Redux/actions/tools";
import { useDispatch, useSelector } from "react-redux";
import randomstring from "randomstring";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import whiteboardLogo from "../../assets/Images/whiteboardLogo.png";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../Redux/actions/boards";
import { getFrameById } from "../../Redux/actions/frame";
import LoadingSpinner from "./LoadingSpinner";
import ShareModal from "../share/shareModal";

const DrawerHeader = ({
  handleZoomIn,
  handleZoomOut,
  handleExport,
  board_Id,
}) => {
  const shareUrl = window.location.href;
  const [highlighted, setHighlighted] = useState(false);
  const [isDayMode, setIsDayMode] = useState(true);
  const [stream, setStream] = useState(null);
  const navigate = useNavigate();
  const { boardData } = useSelector((store) => store.boardRoot);
  const { selectedFrame, frames } = useSelector((store) => store.frameRoot);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [visibleFrameIndex, setVisibleFrameIndex] = useState(0);

  const handleToggleMode = () => {
    setIsDayMode(!isDayMode);
  };

  const sharePage = async () => {
    try {
      await navigator.share({
        title: "Draw",
        text: "Check out this page!",
        url: shareUrl,
      });
    } catch (error) {}
  };

  const handleIconClick = (iconName) => {
    setHighlighted(iconName);
  };
  const handleAddFrame = useCallback(
    async (iconName) => {
      setLoading(true);
      try {
        let generatedFrameId = randomstring.generate({
          length: 10,
          charset: "alphabetic",
        });

        const newFrame = {
          name: `frame ${frames.length + 1}`,
          generatedFrameId,
          boardId: boardData?._id ?? board_Id,
          gridSize: Math.min(1500, 1200) / 8,
          stageWidth: 1500,
          stageHeight: 1200,
          zoomPercentage: 100,
          scale: { x: 1, y: 1 },
        };

        await dispatch(addFrame(newFrame));
        await dispatch(getFrameById(board_Id, frames.length));
        setVisibleFrameIndex(frames.length);
      } finally {
        setLoading(false);
      }
    },
    [boardData, dispatch, frames]
  );

  const handleClosePopup = () => {
    // setShowPopup(false);
    if (stream) {
      // Stop the webcam stream when closing the popup
      stream.getTracks().forEach((track) => track.stop());
      setStream(null); // Reset the stream state to null
    }
  };

  // const handleAddFromLink = () => {
  //   const photoLink = window.prompt("Enter the photo link");
  //   if (photoLink) {
  //     const image = new Image();
  //     image.onload = () => {
  //       // Image loaded successfully, handle the logic to process the image
  //       console.log("Image loaded successfully:", photoLink);
  //     };
  //     image.onerror = () => {
  //       // Error loading the image, handle the error
  //       console.error("Error loading the image:", photoLink);
  //     };
  //     image.src = photoLink;
  //   }
  // };

  const handleCaptureImage = () => {
    const videoElement = document.getElementById("webcamVideo");
    const canvasElement = document.createElement("canvas");
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;
    const canvasContext = canvasElement.getContext("2d");
    canvasContext.drawImage(videoElement, 0, 0);
    const imageDataURL = canvasElement.toDataURL("image/png");
    console.log("Captured image:", imageDataURL);
  };

  const handleAccessWebcam = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setStream(stream);
      })
      .catch((error) => {
        console.log("Error accessing webcam:", error);
      });
  };

  //change frame to next if any
  const showNextFrame = async () => {
    if (visibleFrameIndex < frames.length - 1) {
      setLoading(true);
      try {
        await dispatch(getFrameById(board_Id, visibleFrameIndex + 1));
        setVisibleFrameIndex((prevIndex) => prevIndex + 1);
      } finally {
        setLoading(false);
      }
    }
  };

  //change frame to previous if any
  const showPreviousFrame = async () => {
    if (visibleFrameIndex > 0) {
      setLoading(true);
      try {
        await dispatch(getFrameById(board_Id, visibleFrameIndex - 1));
        setVisibleFrameIndex((prevIndex) => prevIndex - 1);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFrameDelete = () => {
    if (visibleFrameIndex < frames.length - 1) {
      showNextFrame();
    } else {
      showPreviousFrame();
    }
  };

  const handleUndo = () => {
    let latestElementIntoUndoStack =
      selectedFrame?.undoStack[selectedFrame?.undoStack.length - 1];
    if (latestElementIntoUndoStack) {
      dispatch(
        removeInFrames(
          selectedFrame?.boardId,
          selectedFrame?._id,
          latestElementIntoUndoStack.data,
          latestElementIntoUndoStack.type
        )
      );
    }
  };

  const handleRedo = () => {
    let latestElementIntoRedoStack =
      selectedFrame?.redoStack[selectedFrame?.redoStack.length - 1];
    if (latestElementIntoRedoStack) {
      dispatch(redoInFrame(selectedFrame?.boardId,selectedFrame?._id, latestElementIntoRedoStack));
    }
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        position: "absolute",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0.2rem 0",
        position: "fixed",
        top: 0,
      }}
    >
      <div
        style={{
          backgroundColor: Colors.PUREWHITE,
          height: "40px",
          width: "fit-content",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {loading && <LoadingSpinner />}
        <img
          src={whiteboardLogo}
          style={{
            cursor: "pointer",
            maxHeight: "100px",
            maxWidth: "120px",
            borderRadius: "10px",
          }}
          alt="NoImage"
          onClick={() => {
            handleIconClick("icon9");
            dispatch(userLogout());
            navigate("/");
          }}
        />
{/* 
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={BoardPopover(frames)}
          rootClose={true}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BiFolder
              size={30}
              title="Frames Folder"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                margin: "15px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon1" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => {
                handleIconClick("icon1");
              }}
            />
          </div>
        </OverlayTrigger>
        <button
          title="Add New Frame"
          style={{
            cursor: "pointer",
            border: "none",
            borderRadius: "4px",
            padding: "8px 10px",
            backgroundColor: Colors.MEDIUMGRAY,
            fontWeight: "bold",
            color: Colors.WHITE,
            margin: "5px",
            transition: "background-color 0.3s",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleAddFrame}
        >
          <BsFillPlusCircleFill size={20} />
        </button> */}
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "inline",
          flexWrap: "wrap",
        }}
      >
        <button
          style={{
            display: "inline-flex",
            alignItems: "center",
            padding: "3px",
            borderRadius: "5px",
            backgroundColor: Colors.LIGHTGRAY,
            border: `1px solid ${Colors.GRAY}`,
          }}
          onClick={showPreviousFrame}
        >
          <BiLeftArrow style={{ cursor: "pointer" }} />
        </button>
        <div style={{ padding: "4px" }}>
          <ul
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "0",
              display: "flex",
            }}
          >
            <li
              key={frames[visibleFrameIndex]?.id}
              style={{
                display: "inline-block",
                textAlign: "center",
                justifyContent: "center",
                padding: "4px",
                margin: "4px",
                fontWeight: "bold",
                cursor: "pointer",
                borderRadius: "5px",
                backgroundColor: Colors.MEDIUMGRAY,
                color: Colors.WHITE,
              }}
            >
              Frame {visibleFrameIndex + 1}
            </li>
          </ul>
        </div>
        <button
          style={{
            display: "inline-flex",
            alignItems: "center",
            padding: "3px",
            borderRadius: "5px",
            backgroundColor: Colors.LIGHTGRAY,
            border: `1px solid ${Colors.GRAY}`,
          }}
          onClick={showNextFrame}
        >
          <BiRightArrow style={{ cursor: "pointer" }} />
        </button>
      </div> */}

      {/* Header Icons */}
      {/* <div
        style={{
          backgroundColor: Colors.WHITE,
          padding: "10px",
          height: "40px",
          width: "fit-content",
          borderRadius: "10px",
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          cursor: "pointer",
          outline: `1px solid ${Colors.GRAY}`,
        }}
      > */}
      {/* <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={Timer({ setSelectedTool })}
          rootClose={true}
        >
          <div
            onClick={() => setSelectedTool("line")}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GiAlarmClock
              size={22}
              title="Timer"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon1" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => handleIconClick("icon1")}
            />
          </div>
        </OverlayTrigger>

        <BsCameraVideo
          size={22}
          title="Camera"
          style={{
            cursor: "pointer",
            color: Colors.BLACK,
            padding: "2px",
            transition: "background-color 0.3s",
            ...(highlighted === "icon2" && {
              backgroundColor: Colors.LIGHTGRAY,
              borderRadius: "4px",
            }),
          }}
          onClick={() => handleIconClick("icon2")}
        />
        <BsThreeDots
          size={22}
          title="More"
          style={{
            cursor: "pointer",
            color: Colors.BLACK,
            padding: "2px",
            transition: "background-color 0.3s",
            ...(highlighted === "icon3" && {
              backgroundColor: Colors.LIGHTGRAY,
              borderRadius: "4px",
            }),
          }}
          onClick={() => handleIconClick("icon3")}
        />
        <BsEmojiSunglasses
          size={22}
          title="Private Mode"
          style={{
            cursor: "pointer",
            color: Colors.BLACK,
            padding: "2px",
            transition: "background-color 0.3s",
            ...(highlighted === "icon4" && {
              backgroundColor: Colors.LIGHTGRAY,
              borderRadius: "4px",
            }),
          }}
          onClick={() => handleIconClick("icon4")}
        />
        <BsBell
          size={22}
          title="Notifications"
          style={{
            cursor: "pointer",
            color: Colors.BLACK,
            padding: "2px",
            transition: "background-color 0.3s",
            ...(highlighted === "icon5" && {
              backgroundColor: Colors.LIGHTGRAY,
              borderRadius: "4px",
            }),
          }}
          onClick={() => handleIconClick("icon5")}
        />
        <div
          // onClick={() => handleAddNote(200, 300,shape)}
          onClick={handleToggleMode}
        >
          {isDayMode ? (
            <BsSunFill
              size={22}
              title="Day Mode"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon6" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={bgchange}
            />
          ) : (
            <BsMoonFill
              size={22}
              title="Night Mode"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon7" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={bgchange}
            />
          )}
        </div> */}

      {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <BsTrashFill
            size={22}
            title="Trash"
            style={{
              cursor: "pointer",
              color: Colors.BLACK,
              padding: "2px",
              transition: "background-color 0.3s",
              ...(highlighted === "icon8" && {
                backgroundColor: Colors.LIGHTGRAY,
                borderRadius: "4px",
              }),
            }}
            onClick={() => handleIconClick("icon8")}
          />
        </div>
        <div
          onClick={handleExport}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <BsDownload
            size={22}
            title="Download"
            style={{
              cursor: "pointer",
              color: Colors.BLACK,
              padding: "2px",
              transition: "background-color 0.3s",
              ...(highlighted === "icon9" && {
                backgroundColor: Colors.LIGHTGRAY,
                borderRadius: "4px",
              }),
            }}
            onClick={() => handleIconClick("icon9")}
          />
        </div> */}
      {/* <div>
          <div
            onClick={() => {
              handleOpenPopup();
              setSelectedTool("photos");
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HiOutlinePhotograph
              size={22}
              title="Upload Picture"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon10" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => handleIconClick("icon10")}
            />
          </div>

          {/* Popup */}
      {/* {showPopup && (
            <div
              style={{
                position: "fixed",
                inset: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: `0 2px 4px ${Colors.GRAY}`,
                zIndex: 999,
              }}
            >
              <div
                style={{
                  backgroundColor: Colors.PUREWHITE,
                  padding: "20px",
                  borderRadius: "4px",
                  boxShadow: `0 2px 4px ${Colors.MEDIUMGRAY}`,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3>Upload Picture</h3>
                <div style={{ marginBottom: "10px" }}>
                  <button
                    onClick={() => {
                      document.getElementById("imageUpload").click();
                      setShowPopup(false); // Close the popup
                    }}
                    style={{
                      padding: "5px 10px",
                      borderRadius: "4px",
                      border: "none",
                      marginRight: "10px",
                      backgroundColor: Colors.LIGHTGRAY,
                    }}
                  >
                    Select from Computer
                  </button>
                  <button
                    onClick={() => {
                      handleAddFromLink();
                      setShowPopup(false); // Close the popup
                    }}
                    style={{
                      padding: "5px 10px",
                      borderRadius: "4px",
                      border: "none",
                      marginRight: "10px",
                      backgroundColor: Colors.LIGHTGRAY,
                    }}
                  >
                    Add from Link
                  </button>
                  <button
                    onClick={() => {
                      handleAccessWebcam();
                      navigator.mediaDevices
                        .getUserMedia({ video: true })
                        .then((stream) => {
                          // Handle the webcam stream
                          // You can display the webcam video in an element or perform other actions
                          // Example: display the webcam video in a video element with id "webcamVideo"
                          const videoElement =
                            document.getElementById("webcamVideo");
                          videoElement.srcObject = stream;
                          videoElement.play();
                        })
                        .catch((error) => {
                          console.log("Error accessing webcam:", error);
                        });
                    }}
                    style={{
                      padding: "5px 10px",
                      borderRadius: "4px",
                      border: "none",
                      marginRight: "10px",
                      backgroundColor: Colors.LIGHTGRAY,
                    }}
                  >
                    Webcam
                  </button>
                </div>
                {stream && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    <video
                      id="webcamVideo"
                      style={{ width: "400px", height: "auto" }}
                      autoPlay
                    ></video>
                    <button
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        marginTop: "10px",
                        border: "1px solid",
                      }}
                      onClick={handleCaptureImage}
                    >
                      Capture Image
                    </button>
                  </div>
                )}
                <button
                  onClick={handleClosePopup}
                  style={{
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1px solid",
                    fontWeight: "bold",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <input
            type="file"
            id="imageUpload"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
        </div> */}
      {/* </div> */}

      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        <div
          style={{
            outline: `1px solid ${Colors.GRAY}`,
            backgroundColor: Colors.WHITE,
            padding: "10px",
            height: "40px",
            width: "fit-content",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            gap: "0.7rem",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: `0px 2px 4px ${Colors.LIGHTGRAY}`,
          }}
        >
          {/* <VscCommentDiscussion
            size={22}
            title="Comments"
            style={{
              cursor: "pointer",
              color: Colors.BLACK,
              padding: "2px",
              transition: "background-color 0.3s",
              ...(highlighted === "icon2" && {
                backgroundColor: Colors.LIGHTGRAY,
                borderRadius: "4px",
              }),
            }}
            onClick={() => handleIconClick("icon2")}
          /> */}
          <div
            onClick={handleUndo}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <FaUndoAlt
              size={20}
              title="Undo"
              style={{
                cursor: "pointer",
                color:
                  selectedFrame?.undoStack?.length > 0
                    ? Colors.BLUE
                    : Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon3" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => handleIconClick("icon3")}
            />
          </div>
          <div
            onClick={handleRedo}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <FaRedo
              size={20}
              title="Redo"
              style={{
                cursor: "pointer",
                color:
                  selectedFrame?.redoStack?.length > 0
                    ? Colors.BLUE
                    : Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon4" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => handleIconClick("icon4")}
            />
          </div>
          {selectedFrame?.zoomPercentage && (
            <div
              style={{
                visibility: selectedFrame?.zoomPercentage
                  ? "visible"
                  : "hidden",
                position: "fixed",
                bottom: "10px",
                right: "10px",
                backgroundColor: Colors.BLACK,
                color: Colors.WHITE,
                padding: "6px",
                borderRadius: "4px",
                zIndex: 1,
              }}
            >
              Zoom: {selectedFrame?.zoomPercentage}%
            </div>
          )}
          <ShareModal handleIconClick={handleIconClick} />
          <div
            onClick={() => {
              handleZoomIn();
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <BsZoomIn
              size={22}
              title="Zoom In"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon6" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => handleIconClick("icon6")}
            />
          </div>
          <div
            onClick={() => {
              handleZoomOut();
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <BsZoomOut
              size={22}
              title="Zoom Out"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon7" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => handleIconClick("icon7")}
            />
          </div>
        </div>
        <div
          style={{
            outline: `1px solid ${Colors.GRAY}`,
            backgroundColor: Colors.WHITE,
            padding: "10px",
            height: "40px",
            width: "fit-content",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            gap: "0.7rem",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: `0px 2px 4px ${Colors.LIGHTGRAY}`,
          }}
        >
          {/* <div
            onClick={handleExport}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <BsDownload
              size={22}
              title="Download"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon8" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => handleIconClick("icon8")}
            />
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <BsTrashFill
              size={22}
              title="Trash"
              style={{
                cursor: "pointer",
                color: Colors.BLACK,
                padding: "2px",
                transition: "background-color 0.3s",
                ...(highlighted === "icon9" && {
                  backgroundColor: Colors.LIGHTGRAY,
                  borderRadius: "4px",
                }),
              }}
              onClick={() => {
                handleIconClick("icon9");
                dispatch(deleteFrame(selectedFrame?.boardId,selectedFrame?._id));
                handleFrameDelete();
              }}
            />
          </div>

          {/* <GiHamburgerMenu
            size={22}
            title="More"
            style={{
              cursor: "pointer",
              color: Colors.BLACK,
              padding: "2px",
              transition: "background-color 0.3s",
              ...(highlighted === "icon10" && {
                backgroundColor: Colors.LIGHTGRAY,
                borderRadius: "4px",
              }),
            }}
            onClick={() => handleIconClick("icon10")}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default DrawerHeader;
