import React, { useState, useRef } from "react";
import { Html } from "react-konva-utils";
import { Colors } from "../../utils/Colors";
import { editStageTool } from "../../Redux/actions/tools";
import { useDispatch, useSelector } from "react-redux";

const stickyStyleContainer = {
  display: "flex",
  justifyContent: "space-between",
  border: "2px solid black",
  borderRadius: "10px",
  gap: "10px",
  backgroundColor: Colors.WHITE,
  padding: "10px",
};

const StickyStyle = ({
  x,
  y
}) => {

  const dispatch = useDispatch();
  const {  editToolData,editToolName } = useSelector(
    (store) => store.toolRoot
  );

  const {  selectedFrame } = useSelector(
    (store) => store.frameRoot
  );

  const [editorState, setEditorState] = useState({
    clickedIcon: null,
    isBold: editToolData?.fontWeight,
    isItalic: editToolData?.isItalic,
    isUnderline: editToolData?.textDecoration,
    selectedFont: editToolData?.fontFamily,
  });

  const colorOptions = [
    Colors.BLACK,
    Colors.WHITE,
    Colors.BLUE,
    Colors.RED,
    Colors.DARK_GREEN,
  ];

  const handleIconClick = (value) => {
    let note = { ...editToolData, textAlign: value };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  };

  const handleBoldClick = () => {

    let fontWeight= !editToolData?.fontWeight
    let note = { ...editToolData, fontWeight: fontWeight };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  
  };

  const handleItalicClick = () => {
    let isItalic= !editToolData?.isItalic
    let note = { ...editToolData, isItalic: isItalic };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  
  };

  const handleUnderlineClick = () => {
    let textDecoration= !editToolData?.textDecoration
    let note = { ...editToolData, textDecoration: textDecoration };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  
  };

  const getIconStyle = (iconName) => {
    return {
      fontSize: "20px",
      color: editorState.clickedIcon === iconName ? "white" : "black",
      cursor: "pointer",
    };
  };

  const getButtonStyle = (isActive) => {
    return {
      border: "none",
      fontWeight: isActive ? "bold" : "normal",
      cursor: "pointer",
      background: "none",
      textDecoration: isActive ? "underline" : "none",
      fontStyle: isActive ? "italic" : "normal",
    };
  };

  const groupRef = useRef(null);

  const handleFontChange = (event) => {
    const fontName = event.target.value;
    let note = { ...editToolData, fontFamily: fontName };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  };

  const handleColorClick = (color) => {
  
    let note = { ...editToolData, textColor: color };
    dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, note,editToolName));
  
  };

  return (
    <Html
      groupRef={groupRef}
      groupProps={{ x, y }}
      divProps={stickyStyleContainer}
    >
      <div style={stickyStyleContainer}>
        <button onClick={handleBoldClick} style={getButtonStyle(editorState.isBold)}>
          B
        </button>
        <button onClick={handleItalicClick} style={getButtonStyle(editorState.isItalic)}>
          I
        </button>
        <button
          onClick={handleUnderlineClick}
          style={getButtonStyle(editorState.isUnderline)}
        >
          U
        </button>
        <span>
          <i
            className="fa fa-align-left"
            style={getIconStyle("left")}
            onClick={() => handleIconClick("left")}
          />
        </span>
        <span>
          <i
            className="fa fa-align-right"
            style={getIconStyle("right")}
            onClick={() => handleIconClick("right")}
          />
        </span>
        <span>
          <i
            className="fa fa-align-center"
            style={getIconStyle("center")}
            onClick={() => handleIconClick("center")}
          />
        </span>
        <select value={editorState.selectedFont} onChange={handleFontChange}>
          <option value="">Select Font</option>
          <option value="Arial">Arial</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Verdana">Verdana</option>
          <option value="Helvetica">Helvetica</option>
          <option value="Courier New">Courier New</option>
          <option value="Georgia">Georgia</option>
          <option value="Trebuchet MS">Trebuchet MS</option>
          <option value="Impact">Impact</option>
          <option value="Comic Sans MS">Comic Sans MS</option>
          <option value="Palatino Linotype">Palatino Linotype</option>
        </select>
        {colorOptions.map((color, index) => (
          <span key={index}>
            <i
              className="fa fa-circle"
              style={{ color: color }}
              onClick={() => handleColorClick(color)}
            />
          </span>
        ))}
      </div>
    </Html>
  );
};

export default StickyStyle;
