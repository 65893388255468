import React, { useRef, useEffect, useCallback, useState } from "react";
import { RegularPolygon, Rect,Text,Transformer } from "react-konva";
import ShapeStyle from "./ShapeStyle";
import TextInput from "./HexagonText";
import {
  removeInFrames,
} from "../../../Redux/actions/frame";
import { editStageTool } from "../../../Redux/actions/tools";
import { useDispatch, useSelector } from "react-redux";

const Pentagon = React.memo(({key, shapeProps }) => {
 
  const shapeRef = useRef();
  const trRef = useRef();
  const dispatch = useDispatch();
  const { selectedFrame } = useSelector((store) => store.frameRoot);
  const { editToolName, editToolData } = useSelector((store) => store.toolRoot);
  const [editing, setEditing] = useState(shapeProps?.id === editToolData?.id);
  const [transform, setTransform] = useState(false);

  useEffect(() => {
    if (editing) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [editing]);
  useEffect(() => {
    // Remove transformer when selectedSquare.id changes
    if (shapeProps.id !== editToolData?.id && editing) {
      setEditing(false);
    }
  }, [shapeProps.id, editToolData?.id, editing]);

  const onDragEnd = () => {
      const node = shapeRef.current;
      const x = node.x();
      const y = node.y();
      const sqWidth = editToolData?.sideLength * Math.sqrt(2);
      const sqX = x - sqWidth / 2;
      const sqY = y - sqWidth / 2;
      dispatch(
        editStageTool(
          selectedFrame?.boardId,
          selectedFrame?._id,
          {
            ...editToolData,
            pentagonCenterX: node.x(),
            pentagonCenterY: node.y(),
            squareX: sqX,
            squareY: sqY,
          },
          editToolName
        )
      );
  };

  const handleTransformEnd = () => {
    const node = shapeRef.current;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const newSideLength = shapeProps.sideLength * scaleX;
    const newWidth =  newSideLength * (1 + Math.sqrt(5));
    const x = node.x()*scaleX;
    const y = node.y()*scaleY;
    const sqWidth = newSideLength * Math.sqrt(2);
    const sqHeight = newSideLength * Math.sqrt(2);
    const sqX = x - sqWidth / 2;
    const sqY = y - sqHeight / 2;
    const radius = newSideLength * (1 + Math.sqrt(5)) / 2;


    let data = {
      pentagonCenterX: x,
      pentagonCenterY: y,
      squareWidth: sqWidth,
      squareHeight: sqHeight,
      squareX: sqX,
      squareY: sqY,
      width: newWidth,
      sideLength: newSideLength,
      radius: radius,
    };
    dispatch(
      editStageTool(
        selectedFrame?.boardId,
        selectedFrame?._id,
        {
          ...editToolData,
          ...data,
        },
        editToolName
      )
    );
  };

  const handleDoubleClick = () => {
    dispatch({
      type: "EDIT_SELECTED_TOOL",
      payload: {
        isEditToolSelected: true,
        editToolName: "pentagon",
        editToolData: shapeProps,
        editedToolId: shapeProps.id,
      },
    });
    setEditing(true);
    setTransform(true);
  };

  const handleBlur = (deleting) => {
    if (editToolData && Object.keys(editToolData).length > 0 && !deleting && !transform) {
      setEditing(false);
      dispatch(editStageTool(selectedFrame?.boardId,selectedFrame?._id, editToolData, editToolName));
     }
  };

  const handleChange = (value) => {
    setTransform(false);
    dispatch({
      type: "UPDATE_EDIT_SELECTED_TOOL",
      payload: { name: "text", value },
    });
  };

  return (
    <>
   

      <RegularPolygon
        key={key}
        onClick={() => handleDoubleClick()}
        ref={shapeRef}
        {...shapeProps}
        draggable
        stroke={0.5}
        width={shapeProps.width}
        radius={shapeProps.radius}
        x={shapeProps.pentagonCenterX}
        y={shapeProps.pentagonCenterY}
        sides={5} // Set the number of sides for a pentagon
        cornerRadius={0} // Set cornerRadius to 0
        onDragEnd={onDragEnd}
        onDragStart={handleDoubleClick}
        onTransformStart={handleDoubleClick}
        onTransformEnd={handleTransformEnd}
       
      />

      <Rect
        x={shapeProps?.squareX}
        y={shapeProps?.squareY}
        width={shapeProps?.squareWidth}
        height={shapeProps?.squareWidth}
        id="rectangle"
        draggable
        onClick={handleDoubleClick}
      />
      <Text
        fontSize={15}
        fill="white"
        x={shapeProps?.squareX}
        y={shapeProps?.squareY}
        width={shapeProps?.squareWidth}
        height={shapeProps?.squareWidth}
        align="center"
        verticalAlign="middle"
        id="rectangleId"
        onClick={handleDoubleClick}
        ref={trRef}
      />

            <TextInput
              editing={editing}
              x={shapeProps?.squareX}
              y={shapeProps?.squareY}
              width={shapeProps?.squareWidth}
              height={shapeProps?.squareWidth}
              value={shapeProps.text}
              onBlur={() => handleBlur(false)}
              onChange={(e) => handleChange(e)}
              onSelect={handleDoubleClick}
            />
         {editing && (
        <ShapeStyle
          x={shapeProps.pentagonCenterX}
          y={shapeProps.pentagonCenterY - shapeProps.width / 2 - 60}
          onClose={() => setEditing(false)}
          onDelete={() =>
            dispatch(
              removeInFrames(selectedFrame?.boardId,selectedFrame?._id, editToolData, "pentagon")
            )
          }
          onColorChange={(color) =>
            dispatch(
              editStageTool(selectedFrame?.boardId,selectedFrame?._id, {
                ...editToolData,
                fill: color,
              },editToolName)
            )
          }
        />
      )}
      {editing && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 50 || newBox.height < 50) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
})
export default Pentagon;