import React, { useState } from "react";
import { BiText, BiRectangle } from "react-icons/bi";
import { IoMdBrush } from "react-icons/io";
import ColorPopover from "../colorPopover";
import TextPopover from "../Text/TextPopover";
import { BsPencil, BsStickyFill } from "react-icons/bs";
import { HiOutlinePhotograph } from "react-icons/hi";
import { HiCursorClick } from "react-icons/hi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CustomPopover from "../popover";
import CustomStickyPopover from "../customStickyPopover";
import ShapePopover from "../Shape/ShapePopover";
import { toolIds } from "../../../utils/ToolId";
import styles from "../drawingArea.module.css";
import { useDispatch, useSelector } from "react-redux";

const SidebarTool = ({ setIsLaser, isLaser, handleImageUpload }) => {
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();

  const { selectedIcon } = useSelector((store) => store.toolRoot);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleIconClick = (icon) => {
    dispatch({
      type: "SET_SELECTED_ICON",
      payload: icon,
    });
  };
  const handleSelectedTool = (toolName) => {
    dispatch({
      type: "SET_SELECTED_TOOLNAME",
      payload: toolName,
    });
    dispatch({
      type:"UPDATE_PENSTROKE",
      payload:{
        width:2,
        stroke: "black",
        strokeWidth: 2
      }
    })
  };

  const handleAddFromLink = () => {
    const photoLink = window.prompt("Enter the photo link");
    if (photoLink) {
      const image = new Image();
      image.onload = () => {};
      image.onerror = () => {
        // Error loading the image, handle the error
        console.error("Error loading the image:", photoLink);
      };
      image.src = photoLink;
    }
  };

  return (
    <div className={styles.draw_options}>
      <div className={styles.draw_button_container}>
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={CustomPopover()}
          rootClose={true}
        >
          <div
            onClick={() => handleSelectedTool(toolIds.LINE)}
            className={`${styles.icon_button} ${styles.draw_button} ${
              selectedIcon === "icon1" ? styles.icon_button_icon1 : ""
            }`}
          >
            <BsPencil
              size={18}
              title="Draw"
              onClick={() => handleIconClick("icon1")}
            />
          </div>
        </OverlayTrigger>
        {/* stickynotes */}
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={CustomStickyPopover()}
          rootClose={true}
        >
          <div
            className={`${styles.icon_button} ${styles.draw_button} ${
              selectedIcon === "icon2" ? styles.icon_button_icon1 : ""
            }`}
          >
            <BsStickyFill
              size={18}
              title="Sticky Note"
              onClick={() => {
                handleIconClick("icon2");
                handleSelectedTool(toolIds.STICKY);
              }}
            />
          </div>
        </OverlayTrigger>
        {/* 
        <div
          variant="light"
          onClick={() => handleSelectedTool(toolIds.BRUSH)}
          className={`${styles.icon_button} ${styles.draw_button}`}
        >
          <OverlayTrigger
            trigger="click"
            placement="right"
            overlay={ColorPopover()}
            rootClose={true}
          >
            <div
              className={`${
                selectedIcon === "icon3" ? styles.icon_button_icon1 : ""
              }`}
            >
              <IoMdBrush
                size={18}
                title="Brush Stroke"
                onClick={() => handleIconClick("icon3")}
              />
            </div>
          </OverlayTrigger>
        </div> */}

        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={TextPopover()}
          rootClose={true}
        >
          <div
            className={`${styles.icon_button} ${styles.draw_button} ${
              selectedIcon === "icon4" ? styles.icon_button_icon1 : ""
            }`}
            onClick={() => handleSelectedTool(toolIds.TEXT)}
          >
            <BiText
              size={18}
              title="Text"
              onClick={() => handleIconClick("icon4")}
            />
          </div>
        </OverlayTrigger>

        {/* shapes popover */}
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={ShapePopover()}
          rootClose={true}
        >
          <div
            className={`${styles.icon_button} ${styles.draw_button} ${
              selectedIcon === "icon5" ? styles.icon_button_icon1 : ""
            }`}
          >
            <BiRectangle
              size={18}
              title="Draw"
              onClick={() => handleIconClick("icon5")}
            />
          </div>
        </OverlayTrigger>

        {/* <div>
          <div
            onClick={() => {
              handleOpenPopup();
              handleSelectedTool(toolIds.PHOTOS);
            }}
            className={`${styles.icon_button} ${styles.draw_button} ${
              selectedIcon === "icon6" ? styles.icon_button_icon1 : ""
            }`}
          >
            <HiOutlinePhotograph
              size={18}
              title="Upload Picture"
              onClick={() => handleIconClick("icon6")}
            />
          </div>
          {showPopup && (
            <div className={styles.image_upload_popup}>
              <div className={styles.image_upload_popup_content}>
                <h3>Upload Picture</h3>
                <div className={styles.main}>
                  <button
                    onClick={() => {
                      document.getElementById("imageUpload").click();
                      setShowPopup(false); // Close the popup
                    }}
                    className={styles.image_upload_button}
                  >
                    Select from Computer
                  </button>
                  <button
                    onClick={() => {
                      handleAddFromLink();
                      setShowPopup(false); // Close the popup
                    }}
                    className={styles.image_upload_button}
                  >
                    Add from Link
                  </button>
                  <button
                    onClick={() => {
                      navigator.mediaDevices
                        .getUserMedia({ video: true })
                        .then((stream) => {
                          // Handle the webcam stream
                          // You can display the webcam video in an element or perform other actions
                          // Example: display the webcam video in a video element with id "webcamVideo"
                          const videoElement =
                            document.getElementById("webcamVideo");
                          videoElement.srcObject = stream;
                          videoElement.play();
                        })
                        .catch((error) => {
                          console.log("Error accessing webcam:", error);
                        });
                      setShowPopup(false); // Close the popup
                    }}
                    className={styles.image_upload_button}
                  >
                    Webcam
                  </button>
                </div>
                <button
                  onClick={handleClosePopup}
                  className={styles.image_upload_button_cancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <input
            type="file"
            id="imageUpload"
            className={styles.input_button}
            onChange={handleImageUpload}
          />
        </div> */}
        {/* <div
          className={`${styles.icon_button} ${styles.draw_button} ${
            selectedIcon === "icon7" ? styles.icon_button_icon1 : ""
          }`}
        >
          {" "}
          <HiCursorClick
            size={18}
            title="Laser Pointer"
            onClick={() => {
              handleIconClick("icon7");
              setIsLaser(!isLaser);
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default SidebarTool;
